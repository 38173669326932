import React, {useState} from 'react';
import {ReactComponent as Logo} from '../../../assets/images/logo.svg';
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import "./styles.css";
import {CheckIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {useNavigate} from "react-router-dom";
import {login} from "../../../api/auth";
import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {loginSuccess} from "../../../redux/slices/authSlice";
import {getUserById} from "../../../api/user";
import { jwtDecode } from "jwt-decode";
import {addUser} from "../../../redux/slices/userSlice";
import WInfo from "../../../components/WInfo";
import WLoader from "../../../components/WLoader/index.";
import { getVersionString } from '../../../config/version';
import {reset} from "../../../redux/slices/projectSlice";
import {resetAccount} from "../../../redux/slices/accountSlice";

interface LoginParams {
    username: string;
    password: string;
}

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {mutate, isLoading} = useMutation(async ({username, password}: LoginParams) => {
        return await login(username, password);
    }, {
        onSuccess: async (data) => {
            dispatch(reset());
            dispatch(resetAccount())
            dispatch(loginSuccess(data));
            try {
                const decodedToken = jwtDecode(data.accessToken);
                const userId = Number(decodedToken.sub);
                const user = await getUserById(userId);
                dispatch(addUser(user));
            } catch (error) {
                console.error("Erreur lors du décodage du token JWT ou lors de la récupération de l'utilisateur:", error);
            }

            navigate('/projects');
        },
        onError: (error) => {
            setErrorMessage('Nom d\'utilisateur ou mot de passe incorrect.');
            setVisible(true);
            console.error('Login error:', error);
        },
    });

    const handlePricing = () => {
        navigate('/pricing-plans');
    };

    const handleSubmit = async () => {
        if(username != null && username != "" && username != undefined &&
            password != null && password != "" && password != undefined){
            setVisible(false);
            mutate({username, password});
        }else{
            setErrorMessage('Les champs ci-dessous doivent être renseignés.');
            setVisible(true);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="flex h-screen flex-col lg:flex-row">
            {/* Section de gauche - 500px */}
            <div className="md:w-[500px] w-full flex flex-col items-center p-10 m-auto lg:m-0">
                {/* Logo */}
                <div className="mb-6 mt-28">
                    <Logo className="w-52 h-auto"/>
                </div>

                {/* Formulaire de connexion */}
                <div className="w-full max-w-[400px]">

                    {/* Validateur */}
                    {error &&
                        <div className="mb-6">
                            <WInfo variant="error">{errorMessage}</WInfo>
                        </div>
                    }

                    <div className="mb-4">
                        <WInput name="email"
                                placeholder="E-mail"
                                required={true}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <WInput
                            name="password"
                            type="password"
                            required={true}
                            onKeyDown={handleKeyPress}
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <WButton onClick={handleSubmit} variant="secondary" className="w-full">Connexion</WButton>
                    <a href="/forgot-password" className="block text-green-600 text-sm font-medium underline mt-3">Mot de passe
                        oublié ?</a>
                </div>

                <div className="border-t-2 border-gray-500 mt-8 border-dotted text-center block max-w-[400px] lg:hidden w-full">
                    <div className="font-bold text-lg mt-8 ml-3 mr-3">Pas encore inscrit ?</div>
                    <div className="font-normal m-auto ml-3 mr-3">Créez votre premier projet</div>
                    <WButton className="w-full max-w-[400px] mt-8 mb-8"
                             variant="tertiary"
                             onClick={handlePricing}>
                        Commencer ici
                    </WButton>
                </div>


                {/* Lien tout en bas */}
                <a href="https://wattless.fr/" target="_blank" rel="noreferrer"
                   className="lg:absolute bottom-0 text-gray-400 font-medium text-[15px] underline mt-3 mb-6">
                    Retour au site internet
                </a>
                <span className="text-gray-400 text-xs mt-2">
                    {getVersionString()}
                </span>
            </div>

            {/* Section de droite - Reste de l'écran */}
            <div className="flex-1 bg-img bg-gray-100 flex flex-col items-center justify-center">

                <div className="w-[768px] login-desc-block text-left hidden lg:block max-w-[80%]">

                    <h2 className="ml-8 text-3xl font-bold mb-6">Pas encore inscrit ?</h2>
                    <h3 className="ml-8 font-bold mb-6">Créez votre premier projet pour :</h3>

                    {/* Checkpoints */}
                    <ul className="list-none pl-0 font-medium text-base">
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Optimiser votre DPE
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Faire un plan d’action des travaux nécessaires
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Évaluer le coût des travaux
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Évaluer les économies d’énergies que vous allez réaliser
                        </li>
                    </ul>

                    <div className="flex flex-row mt-6 justify-center items-center">
                        <img src="/images/redG.png" alt="Checkmark" className="h-50 w-50 mr-2"/>
                        <span
                            className="border-b-4 border-dotted border-gray-600 relative w-[180px] left-[14px]">
                        </span>
                        <ChevronRightIcon className="w-10 mr-3"/>
                        <img src="/images/greenB.png" alt="Checkmark" className="h-50 w-50 mr-2"/>
                    </div>

                    <div className="flex flex-row mt-12 justify-center items-center">
                        <WButton variant="tertiary" onClick={handlePricing}>Commencer ici</WButton>
                    </div>
                </div>

            </div>
            {isLoading &&  <WLoader 
                type="loading"
                message="Chargement de vos projets"
                subMessage="Veuillez patienter quelques instants"
            />}
        </div>
    );
};

export default LoginPage;
