import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import WButton from "../../../../components/Wbutton";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../../redux/slices/projectSlice";
import {useMutation} from "react-query";
import {lancerCalcul} from "../../../../api/project";
import WLoader from "../../../../components/WLoader/index.";

const Choice: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const currentProject = useSelector(selectCurrentProject);

    const {mutate} = useMutation(async ({projectId}: {
        projectId: number
    }) => {
        setIsLoading(true);
        const planActionList = await lancerCalcul(projectId);
        if(!planActionList){
            navigate('/erreurgenerationsceneration')
        }
        setIsLoading(false);
    }, {
        onSuccess: () => {
            navigate('/objective')
        }
    });

    const handlePrevious = () => {
        navigate('/validation')
    };

    const handleOk = () => {
        navigate('/financial')
    };

    const handleLater = (e: React.FormEvent) => {
        e.preventDefault();
        if (currentProject?.id) {
            mutate({projectId: currentProject.id});
        }
    };

    // Vérifie si currentProject est nul
    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    if (isLoading) {
        return <WLoader message={'Merci de patienter le temps de déterminer les meilleurs offres de plans de travaux pour votre actif'}/>
    }

    return (
        <div className="text-left mb-6">
            <div className="flex mt-12 mx-6">
                <div
                    className="text-[19px] leading-7 text-[#5FBB46] border-4 border-[#899BB4] font-bold h-9 w-9 text-center rounded-full">€
                </div>
                <div className="w-[calc(100%-2.25rem)]">
                    <div className="font-bold text-lg ml-3 mr-3">Aides financières et subventions</div>
                    <div className="font-normal m-auto ml-3 mr-3">
                        Vous pouvez éventuellement bénéficier d’aides financières pour vous aider dans votre projet
                        de rénovation.
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                <div className="flex flex-col p-6 md:p-12 ">
                    <div className="flex flex-col m-auto justify-center items-center">
                        <WButton variant="secondary" onClick={handleOk}
                                 className="mb-6 w-full">Calculez mon éligibilité aux aides
                            maintenant</WButton>
                        <WButton variant="primary" onClick={handleLater}
                                 className="mb-8 w-full">Caculer plus tard</WButton>
                    </div>

                    <p className="mt-12 text-left">De quoi avez-vous besoin pour calculer votre éligibilité aux
                        aides
                        ?</p>
                    <ul className="text-[#7C90AA] text-sm text-left ml-6 mt-6">
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            1 minute pour remplir le formulaire
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Code postal du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Revenu fiscal du foyer du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Nombre de personnes dans le ménage du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Est-ce que le demandeur a déjà bénéficier d’un Eco-Prêt à taux zéro pour son logement
                        </li>
                    </ul>

                    <div className="flex justify-between mt-20">
                        <WButton variant="primary" onClick={handlePrevious}>Précédent</WButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Choice;