import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";
import WButton from "../../../../components/Wbutton";
import WInput from "../../../../components/WInput";
import {
    IOfferMoment,
    IPaymentRequest,
    IResponsePayment,
    updateCoupon,
    validateCoupon
} from "../../../../api/payment";
import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {updateAccount} from "../../../../redux/slices/accountSlice";
import {FiLogOut} from "react-icons/fi";

interface StripeFormProps {
    plan: IPaymentRequest;
    year: boolean;
    payment: IResponsePayment;
    pack: IOfferMoment;
}

const StripeForm: React.FC<StripeFormProps> = ({plan, year, payment, pack}) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [percent, setPercent] = useState(0);

    const handleSubmitStripe = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        dispatch(updateAccount({
            nom: plan.nom,
            prenom: plan.prenom,
            adresse: plan.adresse,
            email: plan.email,
            subscriptionID: plan.subscriptionID,
            codePostal: plan.codePostal,
            ville: plan.ville,
            societe: plan.societe,
            siret: plan.siret,
            mobile: plan.mobile,
            secretKey: plan.secretKey,
            mensuelle: !year,
            customerIDStripe: payment.customerID,
            subscriptionIDStripe: payment.subscriptionID,
            firstInvoiceIDStripe: payment.firstInvoiceID
        }));

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/confirm-payment`,
            },
        });

        if (error) {
            console.log('ERROR', error.message);
        } else {
            navigate('/confirm-payment');
        }
    };

    const {mutate} = useMutation(async ({coupon}: { coupon: string }) => {
        const couponRes = coupon ? await validateCoupon(coupon) : null;
        plan.couponID = (couponRes) ? coupon : "";
        await updateCoupon(payment.subscriptionID, plan.couponID);
        setPercent(couponRes ? couponRes.percent : 0);
        setCoupon((couponRes) ? coupon : "");
    });

    const handleAbandonner = () => {
        navigate('/pricing-plans');
    };

    const handleCoupon = () => {
        setCoupon(coupon);
        mutate({coupon});
    }

    return <div
        className="fixed flex items-center justify-center bg-[#899BB4] bg-opacity-75 w-full h-full z-[80] top-0 left-0">

        <div
            className="flex flex-col md:flex-row justify-items-stretch bg-white p-10 rounded w-full m-1 md:m-10 max-w-[1050px]">
            <div className="flex flex-row justify-between items-center mt-3 mr-32 text-left mb-10 w-full h-full">
            <div className="w-full px-4 md:px-6 lg:px-8">
                <div className="max-w-2xl mx-auto">
                    {/* Card principale */}
                    <div className="bg-white rounded-xl shadow-lg p-4 md:p-4">
                        {/* En-tête du récapitulatif */}
                        <div className="mb-6 md:mb-8">
                            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">Récapitulatif de votre commande</h2>
                            <div className="flex flex-col sm:flex-row sm:items-center bg-blue-50 rounded-lg p-3 md:p-4 mb-6">
                                <div className="flex-1 mb-2 sm:mb-0">
                                    <span className="block sm:inline text-lg font-semibold text-blue-900">{pack.libelle}</span>
                                    <span className="inline-block mt-2 sm:ml-2 px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                                        {year ? 'Annuel' : 'Mensuel'}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Détails du prix */}
                        <div className="space-y-4 mb-6 md:mb-8">
                            {/* Prix HTVA */}
                            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-3 bg-gray-50 rounded-lg">
                                <span className="text-gray-600 mb-1 sm:mb-0">Prix HTVA</span>
                                <span className="font-medium">
                                    {year 
                                        ? `${Number(pack.prixAnnuelHT)}€`
                                        : `${Number(pack.prixMensuelHT)}€`
                                    }
                                </span>
                            </div>

                            {coupon != undefined && coupon != "" && percent > 0 && (
                            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-3 bg-gray-50 rounded-lg">
                                <span className="mb-1 sm:mb-0 text-green-600">Réduction appliquée : {percent}%</span>
                                <span className="font-medium text-green-600">
                                    {year 
                                        ? `${(Number(pack.prixAnnuelHT) * (percent / 100) * -1).toFixed(2)}€`
                                        : `${(Number(pack.prixMensuelHT) * (percent / 100) * -1).toFixed(2)}€`
                                    }
                                </span>
                            </div>
                            )}                           

                            {/* TVA */}
                            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-3">
                                <span className="text-gray-600 mb-1 sm:mb-0">TVA ({pack.valeurTVA}%)</span>
                                <span className="font-medium text-gray-700">
                                    {year 
                                        ? `${(Number(pack.prixAnnuelTtc) - Number(pack.prixAnnuelHT)) }€`
                                        : `${(Number(pack.prixMensuelTtc) - Number(pack.prixMensuelHT)) }€`
                                    }
                                </span>
                            </div>

                            {/* Total */}
                            <div className="flex flex-col sm:flex-row sm:justify-between items-center p-4 bg-blue-600 text-white rounded-lg mt-6">
                                <span className="text-lg mb-2 sm:mb-0">Total TTC</span>
                                <span className="text-xl md:text-2xl font-bold">
                                    {year 
                                        ? `${(Number(pack.prixAnnuelTtc) * (1 - percent / 100)).toFixed(2)}€`
                                        : `${(Number(pack.prixMensuelTtc) * (1 - percent / 100)).toFixed(2)}€`
                                    }
                                </span>
                            </div>
                        </div>

                        {/* Section coupon */}
                        <div className="mt-6 md:mt-8">
                            {percent > 0 ? (
                                <div className="bg-green-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <span className="text-green-700 font-medium">Code promo : {coupon}</span>
                                            <div className="text-green-600 text-sm mt-1">
                                                Réduction appliquée : {percent}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col space-y-4">
                                    <div className="flex flex-col sm:flex-row gap-3">
                                        <WInput
                                            className="flex-1"
                                            name="coupon"
                                            value={coupon}
                                            placeholder="code promo"
                                            onChange={(e) => setCoupon(e.target.value)}
                                        />
                                        <WButton 
                                            variant="secondary"
                                            onClick={handleCoupon}
                                            className="whitespace-nowrap w-full sm:w-auto"
                                        >
                                            Appliquer le code
                                        </WButton>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Lien pour changer de plan */}
                        <div className="mt-6 text-center">
                            <button
                                onClick={() => navigate('/pricing-plans')}
                                className="text-blue-600 hover:text-blue-800 transition-colors duration-200 text-sm font-medium"
                            >
                                Modifier mon choix de solution
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="flex flex-col w-full">
                <div
                    onClick={handleAbandonner}
                    className="flex items-center space-x-2 cursor-pointer text-red-500 justify-end mb-8"
                >
                    <FiLogOut className="h-6 w-6"/>
                    <span className="font-normal text-gray-500 hidden md:block">Abandonner</span>
                </div>
                <form onSubmit={handleSubmitStripe}>

                    <PaymentElement/>
                    <WButton variant="secondary" className='mt-6' disabled={!stripe}>Payer</WButton>
                </form>
            </div>
        </div>
    </div>;
};

export default StripeForm;