import {LoginResponse, TempCredentials, Token} from "./interfaces/AuthInterfaces";
import axios, {AxiosResponse} from "axios";

const API_URL = process.env.REACT_APP_API_AUTH_URL;

export const login = async (username: string, password: string): Promise<Token> => {
    const response: AxiosResponse<LoginResponse> = await axios.post('/authenticate', {
        username,
        password,
    }, {
        baseURL: API_URL,
    });

    if (response.data.status !== 200) {
        throw new Error(response.data.message || 'Une erreur inattendue est survenue.');
    }

    return response.data.data;
};

export const forgotPassword = async (email: string): Promise<null> => {
    const response = await axios.post(`/auth/forgot-password`, {
        email: email
    }, {
        baseURL: API_URL,
    });
    return response.data.data;
};

export const resetPassword = async (token: string, newPassword: string): Promise<null> => {
    const response = await axios.post(`/auth/reset-password`, {
        token: token,
        newPassword: newPassword
    }, {
        baseURL: API_URL,
    });
    return response.data.data;
};

const CREDENTIALS_MAX_AGE = 600000;
export const authUtils = {
    storeCredentials: (email: string, password: string) => {
        const credentials: TempCredentials = {
            email,
            password,
            timestamp: new Date().getTime()
        };
        const encoded = btoa(JSON.stringify(credentials));
        sessionStorage.setItem('tempCredentials', encoded);
    },

    getStoredCredentials: (): TempCredentials | null => {
        try {
            const encoded = sessionStorage.getItem('tempCredentials');
            if (!encoded) return null;

            const credentials: TempCredentials = JSON.parse(atob(encoded));

            if (new Date().getTime() - credentials.timestamp > CREDENTIALS_MAX_AGE) {
                sessionStorage.removeItem('tempCredentials');
                return null;
            }

            return credentials;
        } catch {
            sessionStorage.removeItem('tempCredentials');
            return null;
        }
    },

    clearCredentials: () => {
        sessionStorage.removeItem('tempCredentials');
    }
};