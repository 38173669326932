import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
import {useQuery} from "react-query";
import {getPlan} from "../api/payment";

interface IMainLayoutProps {
    hasBanner?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({hasBanner = false}) => {

    const {data: planPrice} = useQuery(['getPlanPrice'], async () => {
        return await getPlan();
    });

    const firstOffreSpecial = planPrice?.offreSpecial?.[0];
    return (
        <div className="flex flex-col min-h-screen">
            <main className="flex flex-col h-svh">
                <div>
                    {hasBanner && firstOffreSpecial && (
                        <div className="ban">
                            <p className="p-4">
                                OFFRE DE LANCEMENT : {firstOffreSpecial.valeurEnPourcentage * 100}% à vie avec le code
                                &quot;<span className="font-bold">{firstOffreSpecial.code}</span>&quot;
                            </p>
                        </div>
                    )}
                    <Link to="/">
                        <Logo className="mt-10 mb-0 mx-auto"/>
                    </Link>
                </div>
                <div className="flex">
                    <Outlet/>                    
                </div>
            </main>
        </div>
    );
};

export default MainLayout;