export const AdminBg = () => (
    <svg width="800" height="600" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Cercle central représentant le contrôle total */}
        <circle cx="400" cy="300" r="150" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Rayons de contrôle */}
        <path d="M400 150L600 300L400 450L200 300L400 150Z" fill="#94A3B8" fillOpacity="0.55"/>
        
        {/* Icônes de paramètres */}
        <circle cx="400" cy="300" r="80" fill="#94A3B8" fillOpacity="0.75"/>
        <path d="M400 240V360M340 300H460" stroke="#94A3B8" strokeWidth="20" fillOpacity="0.8"/>
        
        {/* Éléments de connexion */}
        <path d="M200 200L300 300M500 300L600 200" stroke="#94A3B8" strokeWidth="10" fillOpacity="0.6"/>
        <path d="M200 400L300 300M500 300L600 400" stroke="#94A3B8" strokeWidth="10" fillOpacity="0.6"/>
    </svg>
);