interface VersionInfo {
    version: string;
    environment: string;
}

const versionInfo: VersionInfo = {
    version: '0.0.5',  // Version que vous pouvez modifier manuellement
    environment: process.env.REACT_APP_ENVIRONMENT || 'development'
};

export const getVersionString = (): string => {
    return `v${versionInfo.version}-${versionInfo.environment.toUpperCase()}`;
};