import React, { useState, useRef, useEffect } from 'react';
import { ChatBubbleLeftRightIcon, XMarkIcon, ArrowsPointingOutIcon, ArrowsPointingInIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import axios from "axios";
import {format} from "date-fns";
import {fr} from "date-fns/locale";
import { motion } from 'framer-motion';
import './message.css';

interface Message {
    id: string;
    content: string;
    isAI: boolean;
    timestamp: Date;
    sender: string;
}

const AIChat: React.FC = () => {
    const [showWelcome, setShowWelcome] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const formatMessageTime = (date: Date) => {
        return format(date, 'dd/MM/yyyy HH:mm', { locale: fr });
    };

    const markdownToHTML = (text: string) => {
        return text
            // Convertit les ** ** en balises <strong>
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            // Convertit les * * en balises <em>
            .replace(/\*(.*?)\*/g, '<em>$1</em>')
            // Convertit les numéros suivis d'un point en span numéroté
            .replace(/(\d+)\./g, '<span class="number">$1.</span>')
            // Convertit les retours à la ligne en <br>
            .replace(/\n/g, '<br>')
            // Ajoute des espaces après les tirets
            .replace(/\s+-\s+/g, ' - ');
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (isOpen) {
            setShowWelcome(true);
            const timer = setTimeout(() => {
                setShowWelcome(false);
            }, 3000); // Le message disparaît après 3 secondes
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        // Ajouter le message utilisateur
        const userMessage: Message = {
            id: Date.now().toString(),
            content: message,
            isAI: false,
            timestamp: new Date(),
            sender: 'Utilisateur'
        };

        setMessages(prev => [...prev, userMessage]);
        setMessage('');
        setIsTyping(true);

        try {

            const response = await axios.post(
                "https://wattless.app.n8n.cloud/webhook/22399665-935a-430f-b752-8abfc01dfc8f/chat",
                {
                    chatInput: message,
                    action: "sendMessage",
                    sessionId: "4588020e7e394910a506be2cc8f3e9b6"
                },
                {}
            );

            const messageResponse = response.data.output;

            // Ajouter la réponse de l'IA
            const aiMessage: Message = {
                id: Date.now().toString(),
                content: messageResponse,
                isAI: true,
                timestamp: new Date(),
                sender: 'Assistant IA'
            };

            setMessages(prev => [...prev, aiMessage]);
        } catch (error) {
            console.error('Erreur:', error);
        } finally {
            setIsTyping(false);
        }
    };

    return (
        <>
            {/* Chat Window */}
            {isOpen && (
                <div 
                    className={`fixed z-[9999] transition-all duration-300 ease-in-out
                        ${isExpanded 
                            ? 'bottom-0 right-0 w-full h-full md:bottom-20 md:right-4 md:w-[800px] md:h-[600px] md:rounded-lg' 
                            : 'bottom-20 right-4 w-full h-[80vh] md:w-[350px] md:h-[500px] rounded-lg'
                        } bg-white shadow-2xl flex flex-col`}
                >
                    {/* Header */}
                    <div className="bg-[#7C90AA] p-4 flex justify-between items-center md:rounded-t-lg">
                        <div className="flex items-center gap-2 text-white">
                            <ChatBubbleLeftRightIcon className="h-5 w-5" />
                            <span className="font-semibold">Assistant IA</span>
                        </div>
                        <div className="flex items-center gap-2">
                            {/* Bouton d'expansion visible uniquement sur desktop */}
                            <button 
                                onClick={() => setIsExpanded(!isExpanded)}
                                className="hidden md:block text-white hover:bg-[#8fa0b7] p-1 rounded transition-colors"
                            >
                                {isExpanded ? (
                                    <ArrowsPointingInIcon className="h-5 w-5" />
                                ) : (
                                    <ArrowsPointingOutIcon className="h-5 w-5" />
                                )}
                            </button>
                            <button 
                                onClick={() => setIsOpen(false)}
                                className="text-white hover:bg-[#8fa0b7] p-1 rounded transition-colors"
                            >
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>

                    {/* Message de bienvenue */}
                    <>                       
                        {showWelcome && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                className="absolute top-20 left-0 right-0 mx-auto w-fit"
                            >
                                <div className="bg-white/90 backdrop-blur-sm shadow-lg rounded-full px-6 py-3 flex items-center space-x-3">
                                    <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
                                    <p className="text-gray-700">
                                        Bonjour ! Je suis votre assistant virtuel. Comment puis-je vous aider ?
                                    </p>
                                </div>
                            </motion.div>
                        )}                        
                    </>


                    {/* Reste du composant ... */}

                    {/* Messages Container avec padding ajusté */}
                    <div className="flex-1 overflow-y-auto p-3 md:p-4 space-y-3 md:space-y-4">
                        {messages.map((msg) => (
                            <div
                                key={msg.id}
                                className={`flex ${msg.isAI ? 'justify-start' : 'justify-end'}`}
                            >
                                <div className="max-w-[85%] md:max-w-[80%]">
                                    {/* En-tête du message */}
                                    <div 
                                        className={`text-[10px] md:text-xs mb-1 
                                        ${msg.isAI ? 'text-left' : 'text-right'}`}
                                    >
                                        <span className="font-medium">{msg.sender}</span>
                                        <span className="text-gray-400 ml-2">
                                            {formatMessageTime(msg.timestamp)}
                                        </span>
                                    </div>
                                    
                                    {/* Contenu du message */}
                                    <div
                                        className={`p-2 md:p-3 rounded-lg text-sm md:text-base ${
                                            msg.isAI 
                                                ? 'bg-gray-100 text-gray-800' 
                                                : 'bg-[#7C90AA] text-white'
                                        }`}
                                    >
                                        {msg.isAI ? (
                                            <div 
                                                className="message-content"
                                                dangerouslySetInnerHTML={{ 
                                                    __html: markdownToHTML(msg.content) 
                                                }} 
                                            />
                                        ): (
                                            <div className="message-content">
                                                {msg.content}
                                            </div>
                                        )}                                    
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                        {/* Indicateur de frappe */}
                        {isTyping && (
                            <div className="flex justify-start">
                                <div className="max-w-[85%] md:max-w-[80%]">
                                    <div className="text-[10px] md:text-xs mb-1">
                                        <span className="font-medium">Assistant IA</span>
                                        <span className="text-gray-400 ml-2">
                                            {formatMessageTime(new Date())}
                                        </span>
                                    </div>
                                    <div className="bg-gray-100 p-2 md:p-3 rounded-lg">
                                        <div className="flex space-x-1">
                                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
                                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    {/* Input Container */}
                    <div className="border-t border-gray-200 p-3 md:p-4">
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                placeholder="Écrivez votre message..."
                                className="flex-1 p-2 text-sm md:text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7C90AA]"
                            />
                            <button
                                onClick={handleSendMessage}
                                className="bg-[#7C90AA] text-white p-2 rounded-lg hover:bg-[#8fa0b7] transition-colors"
                            >
                                <PaperAirplaneIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Chat Button - Ajusté pour mobile */}
            <button
                onClick={() => setIsOpen(true)}
                className="fixed bottom-4 right-4 z-[9999] bg-[#7C90AA] text-white p-3 md:p-4 
                rounded-full shadow-lg hover:bg-[#8fa0b7] transition-colors hover:shadow-xl"
            >
                <ChatBubbleLeftRightIcon className="h-5 w-5 md:h-6 md:w-6" />
            </button>
        </>
    );
};

export default AIChat;