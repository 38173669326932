import axios from './axiosInstance';
import {User} from "./interfaces/ProjectInterfaces";
import {SubscriptionData} from "./interfaces/ApiInterfaces";

export const getUser = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data.data;
};

export const getUserById = async (userId: number): Promise<User> => {
    try{
        const response = await axios.get(`/users/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Erreur inattendue :", error);
        return {
            activate: false,
            adresse: null,
            afficherInfosFacturation: false,
            client: null,
            codePostal: null,
            dateCreated: "",
            dateDeleted: null,
            dateUpdated: null,
            deleted: false,
            email: null,
            id: 0,
            mobile: null,
            nbrProjects: null,
            password: "",
            permissions: [],
            prenom: null,
            resetPassword: false,
            role: "",
            roles: [],
            siret: null,
            societe: null,
            telephone: null,
            username: "",
            verifyMail: false,
            ville: null,
            nom: ''};
    }
};

export const getUsers = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
    const response = await axios.post(`/users/${user.id}/informations`, user);
    return response.data.data;
};



export const deleteUser = async (userId: number): Promise<User> => {
    const response = await axios.delete(`/users/${userId}`);
    return response.data.data;
};

/** Détail **/
export const getUserInfoById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}/informations`);
    return response.data.data;
};

export const infoFacturation = async (userId: number): Promise<SubscriptionData> => {
    const response = await axios.get(`/users/${userId}/infofacturation`);
    return response.data.data;
};

interface InviteResponse {
    success: boolean;
    message: string;
    invitedUsers?: {
        email: string;
        status: string;
    }[];
}

export const invite = async (
    userID: number | null,
    emails: string[] | null,
    role: string,
    projectIDs: number[]
): Promise<InviteResponse> => {
    const response = await axios.post(`/users/invite`, {
        userID,
        emails,
        role,
        inviterUserID: 1,
        projectIDs,
    });
    return response.data.data;
};

export const isWattlessAdmin = (currentUser : User): boolean => {
    return currentUser.roles[0]?.name === 'ROLE_ADMIN';
};

export const isAdmin = (currentUser : User): boolean => {
    return currentUser.roles[0]?.name === 'ROLE_USER_WATTLESS';
};

export const isAdminAgent = (currentUser : User): boolean => {
    return currentUser.roles[0]?.name === 'ROLE_ADMIN_AGENT';
};

export const isAgent = (currentUser : User): boolean => {
    return currentUser.roles[0]?.name === 'ROLE_AGENT';
};

export const isInvite = async (currentUser : User): Promise<boolean> => {
    return currentUser.roles[0]?.name === 'ROLE_INVITE';
};
