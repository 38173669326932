import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectProjectById, updateProject as updateProjectRedux} from "../../../redux/slices/projectSlice";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import WButton from "../../../components/Wbutton";
import {useParams} from "react-router-dom";
import {generalInformation, updateProject} from "../../../api/project";
import {useMutation, useQuery} from "react-query";
import dpeA from "../../../assets/icons/DPE_GRAY/DPE A.svg";
import dpeB from "../../../assets/icons/DPE_GRAY/DPE B.svg";
import dpeC from "../../../assets/icons/DPE_GRAY/DPE C.svg";
import dpeD from "../../../assets/icons/DPE_GRAY/DPE D.svg";
import dpeE from "../../../assets/icons/DPE_GRAY/DPE E.svg";
import dpeF from "../../../assets/icons/DPE_GRAY/DPE F.svg";
import dpeG from "../../../assets/icons/DPE_GRAY/DPE G.svg";

import {PencilIcon} from "@heroicons/react/16/solid";
import iconCube from "../../../assets/icons/Cube.png";
import iconElec from "../../../assets/icons/electricity.png";
import iconFolder from "../../../assets/icons/folder.png";
import iconProfil from "../../../assets/icons/profil.png";
import iconInfo from "../../../assets/icons/info.png";

import {B, Structure,} from "../../../api/interfaces/ApiInterfaces";
import WLoader from "../../../components/WLoader/index.";

import {Project, User} from "../../../api/interfaces/ProjectInterfaces";

import Menu from "../menu";
import ApportFree from "../Action/ApportFree";
import EcoTypeEnergies from "../Action/EcoTypeEnergies";
import RepartitionUsage from "../Action/RepartitionUsage";
import Deperdition from "../Action/Deperdition";
import {useTranslation} from "react-i18next";
import {colorDPE, customMarkerIcon} from "../../../constants";
import WToast from "../../../components/WToast";
import PermissionGate from "../../../components/PermissionGate";
import DescriptionLoiClimat from "../../../components/DescriptionLoiClimat";
import {getUsers} from "../../../api/user";

const getValue = (value: string | number | B | undefined) => {
    if (value === undefined) {
        return "";
    }

    return (typeof value === "string" || typeof value === "number") ?
        value : value.donnee;
}

interface ElementsProps {
    elements: Structure[];
}

const Accordion: React.FC<ElementsProps> = ({elements}) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    const {t} = useTranslation();

    // Fonction pour gérer l'ouverture et la fermeture des éléments
    const handleAccordionClick = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="rounded-lg flex-1">
            {elements.map((element, index) => (
                <div key={index} className={`border-b border-gray-300 
                ${index === 0 ? 'rounded-lg' : ''}  
                ${index === elements.length - 1 ? 'rounded-b-2xl' : ''}
                `}>
                    <button
                        className={`w-full text-left p-4 font-medium h-16
                        ${index === 0 ? 'rounded-t-2xl' : ''}
                        ${(index === elements.length - 1 && activeIndex !== index) ? 'rounded-b-2xl' : ''}
                        ${activeIndex === index ? 'bg-[#5FBB46] text-white' : 'bg-[#E7EEF8] text-black'}`}
                        onClick={() => handleAccordionClick(index)}
                    >
                        {getValue(element.reference)}
                    </button>

                    <div className="justify-center">
                        {activeIndex === index &&
                            <table className="table-auto text-left bg-gray-100 w-full">
                                {Object.keys(element).map((k, i) => {
                                    return <tr
                                        key={k}
                                        className={`${i % 2 === 0 ? 'bg-gray-100' : 'bg-white'} block md:table-row p-4 border-t w-full`}>
                                        <td className="break-all block md:table-cell  border-b font-bold p-3">{t(k)}</td>
                                        <td className="break-all block md:table-cell p-3">{getValue(element[k])}</td>
                                    </tr>
                                })}
                            </table>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

const SingleInfo: React.FC = () => {

    /* Récuperation ID param */
    const {id} = useParams<{ id: string }>();
    const numericId = id ? parseInt(id) : null;
    const currentProject = useSelector(selectProjectById(numericId));

    const dispatch = useDispatch();

    const [libelleTemp, setLibelleTemp] = useState(currentProject?.libelle || "");

    const [isEditing, setIsEditing] = useState(false);
    const [isEditingGestionnaire, setIsEditingGestionnaire] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [coordinates, setCoordinates] = useState<{ lat: number, lng: number } | null>(null);
    const [activeDiv, setActiveDiv] = useState(1);
    const {t} = useTranslation();
    const {data: generalInformationData} = useQuery(
        ["projectInfo", numericId],
        async () => {
            return (numericId) ? await generalInformation(numericId) : null;
        }
    );

    const { data: users} = useQuery("users", async () => {
        return await getUsers();
    });

    const {mutate: mutateUpdateProject} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess: () => {
            setShowToast(true);
        }
    });

    const handleSaveLibelle = () => {
        if (currentProject && libelleTemp !== currentProject.libelle) {
            const newProject = {...currentProject, libelle: libelleTemp};
            mutateUpdateProject({project: newProject});
            dispatch(updateProjectRedux(newProject));
        }
        setIsEditing(false);
    };

    const handleSaveGestionnaire = (selectedUser: User) => {
        if (currentProject && selectedUser.id !== currentProject.gestionnaire.id) {
            const newProject = {...currentProject, gestionnaire: selectedUser};
            mutateUpdateProject({project: newProject});
            dispatch(updateProjectRedux(newProject));
        }
        setIsEditingGestionnaire(false);
    };

    useEffect(() => {
        if (currentProject?.adresse) {
            const fullAddress = `${currentProject.adresse.banLabel}`;
            fetchCoordinatesFromAddress(fullAddress).then();
        }
    }, [currentProject]);

    const handleButtonClick = (divIndex: number) => {
        setActiveDiv(divIndex);
    };

    const fetchCoordinatesFromAddress = async (address: string) => {
        const provider = new OpenStreetMapProvider();
        try {
            const results = await provider.search({query: address});
            if (results.length > 0) {
                const {x: lng, y: lat} = results[0];
                setCoordinates({lat, lng});
                console.log({lat, lng});
            } else {
                console.error("Adresse introuvable:", address);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées:", error);
        }
    };

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    if (!currentProject || !generalInformationData) {
        return <WLoader message={"Chargement"}/>;
    }

    return <div className="w-full max-w-[1200px]">
        <div className="w-full flex justify-between mt-6">
            <Menu projectId={currentProject.id}/>
        </div>

        <div className="w-full text-left font-bold text-xl mt-12 ml-2"><span>
            <img src={iconFolder} className="inline mr-3" alt="icon"/></span> Informations
            générales
        </div>
        <div className="flex flex-row flex-wrap items-stretch bg-white rounded-[10px] shadow-md mt-8 m-2">
            <div className="flex flex-col order-last sm:order-first min-w-[312px] flex-1 p-4">
                <div className="text-left bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 relative flex items-center">
                    <img src={iconInfo} alt="info" className="inline mx-3 h-full"/>
                    <span className="font-bold">Nom du projet :</span>
                    {isEditing ? (
                        <input
                            type="text"
                            value={libelleTemp}
                            onChange={(e) => setLibelleTemp(e.target.value)}
                            onBlur={handleSaveLibelle}
                            className="inline-block ml-2 p-1 border border-gray-300 rounded"
                            autoFocus
                        />
                    ) : (
                        <div className="flex items-center">
                            <span className="font-bold text-green-600 underline ml-2">{currentProject.libelle}</span>
                            <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INFORMATION.UPDATE.PROJECTNAME']}>
                                <div
                                    className="absolute right-3 ml-2 p-1 rounded-full hover:bg-gray-300 cursor-pointer flex items-center justify-center"
                                    onClick={() => setIsEditing(true)}
                                >
                                    <PencilIcon className="h-5 w-5 text-gray-600"/>
                                </div>
                            </PermissionGate>
                        </div>
                    )}

                </div>
                <div className="text-left bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 relative flex items-center">
                    <img src={iconProfil} alt="profil" className="inline mx-3 h-[33px]"/>
                    <span className="font-bold">Gestionnaire :</span>

                    {isEditingGestionnaire ? (
                        <>
                            <div className="relative border ml-2 shadow-lg rounded">
                                <select
                                    className="w-full p-2 border rounded-md cursor-pointer"
                                    onChange={(e) => {
                                        const selectedUserId = e.target.value;
                                        const selectedUser = users?.find((user: User) => user.id === parseInt(selectedUserId));
                                        selectedUser && handleSaveGestionnaire(selectedUser);
                                    }}
                                >
                                    <option value="" disabled selected>
                                        Sélectionnez un utilisateur
                                    </option>
                                    {users?.map((user: User) => (
                                        <option key={user.id} value={user.id}>
                                            {user.nom} {user.prenom}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center">
                            <span
                                className={`font-bold underline ml-2 ${currentProject.gestionnaire ? 'text-green-600' : 'text-red-600'}`}>
                                {currentProject.gestionnaire ?
                                    (currentProject.gestionnaire.nom + ' ' + currentProject.gestionnaire.prenom) :
                                    'Aucun Gestionnaire'
                                }
                            </span>
                            <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INFORMATION.UPDATE.GESTIONNAIRE']}>
                                <div
                                    className="absolute right-3 ml-2 p-1 rounded-full hover:bg-gray-300 cursor-pointer flex items-center justify-center"
                                    onClick={() => setIsEditingGestionnaire(true)}
                                >
                                    <PencilIcon className="h-5 w-5 text-gray-600"/>
                                </div>
                            </PermissionGate>
                        </div>
                    )}

                </div>

                <table className="table-auto text-left w-full">
                    <tbody>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">N° Ademe :</td>
                        <td className="p-3">{currentProject?.numeroAdeme}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Adresse :</td>
                        <td className="p-3">{currentProject?.adresse.adresse}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Code postal :</td>
                        <td className="p-3">{currentProject?.adresse.codePostal}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Ville :</td>
                        <td className="p-3">{currentProject?.adresse.ville}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Typologie :</td>
                        <td className="p-3">{currentProject?.adresse.type}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Surface habitable :</td>
                        <td className="p-3">{currentProject?.adresse.surfaceHabitable} m²</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Altitude :</td>
                        <td className="p-3">{currentProject?.adresse.altitude || generalInformationData.infoGenerales.altitude.donnee}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Zone climatique :</td>
                        <td className="p-3">{currentProject?.adresse.zoneClimatique || generalInformationData.infoGenerales.zoneClimatique.donnee}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Énergies :</td>
                        <td className="p-3">{currentProject?.adresse.energies}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
            <div className="flex min-w-[312px] min-h-[250px] flex-1">
                {coordinates && (
                    <div className="h-full w-full mb-6">
                        <MapContainer
                            center={coordinates}
                            zoom={25}
                            style={{height: '100%', width: '100%'}}
                            className="w-full rounded-br-[10px] rounded-tr-[10px]"
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                            <Marker position={coordinates}
                                    icon={customMarkerIcon(generalInformationData.infoGenerales.etiquetteDPE)}>
                                <Popup>{currentProject?.adresse.adresse}</Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                )}
            </div>
        </div>

        <div className="text-left font-bold text-xl mt-12 ml-2"><span>
            <img src={iconElec} className="inline mr-3" alt="iconElec" /></span> Informations énergétiques
        </div>
        <div className="flex flex-row flex-wrap items-stretch">
            <div className="flex flex-col flex-1">
                <div className="flex flex-wrap items-stretch">
                    <div
                        className="flex flex-1 flex-col min-w-[250px] shadow-md p-3 text-white rounded-[10px] m-2 text-center justify-center"
                        style={{backgroundColor: colorDPE[generalInformationData?.infoGenerales.etiquetteConso]}}
                    >
                        Consommation
                        <span
                            className="text-[4rem] font-bold [text-shadow:_4px_4px_5px_rgb(0_0_0_/_30%)] leading-normal">{generalInformationData?.infoGenerales.etiquetteConso}</span>
                        <span className="font-bold">Passoire thermique</span>
                        DPE Actuel
                    </div>

                    <div
                        className="flex flex-1 flex-col min-w-[250px] shadow-md p-3 text-white bg-[#9932CD] rounded-[10px] m-2 text-center justify-center"
                        style={{backgroundColor: colorDPE[generalInformationData?.infoGenerales.etiquetteGES]}}
                    >
                        Gaz à Effet de Serre (GES)
                        <span
                            className="text-[4rem] font-bold [text-shadow:_4px_4px_5px_rgb(0_0_0_/_30%)]  leading-normal">{generalInformationData?.infoGenerales.etiquetteGES}</span>
                        <span className="font-bold">Passoire thermique</span>
                        DPE Actuel
                    </div>
                </div>
                <div className="flex flex-wrap items-stretch">
                    <div
                        className="flex flex-row w-1/2 m-2 min-w-[250px] flex-1 bg-white rounded-[10px] text-center justify-center">
                        <ApportFree apportInterne={parseInt(generalInformationData?.infoGenerales.apportInterne ?? 0)}
                                    apportSolaire={parseInt(generalInformationData?.infoGenerales.apportSolaire ?? 0)}
                        />
                    </div>
                    <div
                        className="flex flex-row w-1/2 m-2 min-w-[250px] flex-1 items-center shadow-md text-white bg-[#00257b99] rounded-[10px] text-center justify-center"
                        style={{backgroundColor: colorDPE[currentProject?.classeEnergetiqueObjectif]}}>
                        <div className="w-1/2"><img
                            src={dpeImages[currentProject?.classeEnergetiqueObjectif as string]}
                            alt={currentProject?.classeEnergetiqueObjectif} className="h-40 m-3"/></div>
                        <div className="flex flex-col text-center items-center w-1/2">
                            <span className="font-bold">Objectif DPE</span>
                            <span
                                className="text-[4rem] font-bold [text-shadow:_4px_4px_5px_rgb(0_0_0_/_30%)] leading-normal">{currentProject?.classeEnergetiqueObjectif}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                <DescriptionLoiClimat projectId={currentProject.id} lettre={currentProject?.classeEnergetiqueActuel}/>
                <EcoTypeEnergies repartitionParEnergie={generalInformationData.repartitionParEnergie}/>
            </div>
        </div>

        <div className="flex flex-row flex-wrap items-stretch">
            <Deperdition deperditionEnergetique={generalInformationData.deperditionEnergetique}/>
            <RepartitionUsage repartitionUsageParEnergie={generalInformationData.repartitionUsageParEnergie}/>
        </div>
        <div className="w-full text-left font-bold text-xl mt-12 ml-2"><span>
            <img src={iconCube} className="inline mr-3" alt="iconCube" /></span>Informations structurelles
        </div>
        <div className="flex flex-col lg:flex-row flex-wrap items-stretch">
            <div className="flex flex-col lg:flex-row flex-1 m-2 bg-white rounded-[10px] shadow-md">

                {/* Section des boutons */}
                <div className="p-5 flex flex-wrap lg:flex-col lg:w-auto w-full justify-center lg:justify-start">
                    {Object.keys(generalInformationData.mapInformationsStructurelles).sort().map((key, index) =>
                        <WButton
                            key={key}
                            variant={activeDiv === index ? "secondary" : "primary"}
                            onClick={() => handleButtonClick(index)}
                            className="w-full lg:w-aut mb-2">{t(key.substring(key.indexOf("_") + 1, key.length))}
                        </WButton>
                    )}
                </div>

                {/* Div de contenu qui change selon le bouton cliqué */}
                <div className="flex flex-1 p-5 w-full lg:min-w-[800px]">
                    {Object.keys(generalInformationData.mapInformationsStructurelles).sort().map((key, index) =>
                        activeDiv === index ? <div key={key} className="flex flex-1">
                            <Accordion elements={generalInformationData.mapInformationsStructurelles[key]}/>
                        </div> : null
                    )}
                </div>
            </div>
        </div>
        {showToast && (
            <WToast
                message="Modification effectué avec succès"
                type="success"
                onClose={() => setShowToast(false)} // Masque le toast après fermeture
            />
        )}
    </div>;
};


export default SingleInfo;