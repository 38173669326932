import axios from './axiosInstance';
import {
    CreateProjectResponse,
    Project,
    Address,
    PlanActionData,
    ILoiClimatResilience, IFinancial, Role, User, DpeConstraintCreateProject
} from "./interfaces/ProjectInterfaces";
import {AxiosResponse} from "axios";
import {Detail, IResponseGeneralInfo} from "./interfaces/ApiInterfaces";
import mockGeneralInformation from './mocks/generalInformation.json'

const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';

export const verifyDPE = async (ademe: string): Promise<DpeConstraintCreateProject> => {
    const response= await axios.get(`/dpe/residentiel/${ademe}`);
    return response.data.data;
};

export const createProject = async (ademe: string): Promise<Project> => {
    const response: AxiosResponse<CreateProjectResponse> = await axios.post(`/projects/create/residentiel/${ademe}`);
    return response.data.data;
};

export const fetchProjectValidation = async (projectId: number): Promise<Address> => {
    const response = await axios.post(`/projects/${projectId}/validation`);
    return response.data.data;
};

export const loiClimatResilience = async (projectId: number): Promise<ILoiClimatResilience> => {
    const response = await axios.get(`/projects/${projectId}/loi_climat_resilience`);
    return response.data.data;
};

export const updateProject = async (project: Project): Promise<Address> => {
    const response = await axios.put(`/projects`, project);
    return response.data.data;
};

export const listProjects = async (): Promise<[Project]> => {
    const response = await axios.get(`/projects`);
    return response.data.data;
};

export const removeProject = async (projectId: number) => {
    const response = await axios.delete(`/projects/${projectId}`);
    return response.data.data;
};

export const getUsageActif = async (): Promise<string[]> => {
    const response = await axios.get(`/projects/lof/usageactif`);
    return response.data.data;
};

export const getStatusDemandeur = async (): Promise<string[]> => {
    const response = await axios.get(`/projects/lof/statusDemandeur`);
    return response.data.data;
};

export const getPlanAction = async (projectId: number): Promise<PlanActionData[]> => {
    const response = await axios.get(`/projects/${projectId}/planaction`);
    return response.data.data;
};

export const getProjectUsers = async (projectId: number): Promise<User[]> => {
    const response = await axios.get(`/projects/${projectId}/shared/users`);
    return response.data.data;
};

export const lancerCalcul = async (projectId: number): Promise<PlanActionData[]> => {
    const response = await axios.post(`/projects/${projectId}/lancercalcul`);
    return response.data.data;
};

export const generalInformation = async (projectId: number): Promise<IResponseGeneralInfo> => {
    if (useMockData) {
        return Promise.resolve(mockGeneralInformation.data);
    }
    const response = await axios.post(`/projects/${projectId}/informationsgenerales/ui`);
    return response.data.data;
};

export const subscriptionDetail = async (projectId: number, planActionId: number): Promise<Detail> => {
    const response = await axios.get(`/projects/${projectId}/planaction/${planActionId}/detail`);
    return response.data.data;
};

export interface financialParams {
    statusDemandeur: string,
    usageActif: string,
    dernierRevenuFiscalReference: number,
    nbrPersonnesDeclarees: number,
    dejaBeneficierEcoPret: boolean,
    taxeFonciere: number
}

export const financialAssistance = async (projectId: number, financialParams: financialParams) => {
    const response = await axios.post(`/projects/add/financialassistance/${projectId}`, financialParams);
    return response.data.data;
};

export const UpdateFinancialAssistance = async (projectId: number, financialParams: financialParams) => {
    const response = await axios.put(`/projects/${projectId}/financialassistance`, financialParams);
    return response.data.data;
};

export const RecalculeFinancialAssistance = async (projectId: number) => {
    const response = await axios.post(`/projects/${projectId}/financialassistance/recalculer`);
    return response.data.data;
};

export const getFinancialAssistance = async (projectId: number) : Promise<IFinancial> => {
    const response = await axios.get(`/projects/${projectId}/financialassistance`);
    return response.data.data;
};

export const getProjectUser = async (userid: number) : Promise<Project[]> => {
    const response = await axios.get(`/projects/shared/users/${userid}`);
    return response.data.data;
};

export const addProjectShare = async (projectId: number, userId: number): Promise<void> => {
    const response = await axios.post(`/projects/${projectId}/shared/users/${userId}`);
    return response.data.data;
};

export const deleteProjectShare = async (projectId: number, userId: number): Promise<void> => {
    const response = await axios.delete(`/projects/${projectId}/delete/share/user/${userId}`);
    return response.data.data;
};

export const getRoles = async (): Promise<Role[]> => {
    const response = await axios.get(`/roles`);
    return response.data;
};



