import React from 'react';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";

const Dpeunmanagedbuilding: React.FC = () => {
    const navigate = useNavigate();

    const handlePrevious = () => {
        navigate('/ademe')
    };

    return (
        <>
            <div className="text-left mb-6">

                <div className="flex mt-12 mx-6">

                    <div>
                        <div className="font-bold text-lg  ml-1 mr-3">ERREUR</div>
                        <div className="font-normal m-auto ml-1 mr-3">
                            Votre DPE n&apos;est pas éligible
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md m-auto mt-12 relative p-8 md:p-20 mx-6">

                    <div className="bg-red-100 rounded-[10px] border-2 border-dashed border-red-500 shadow-md m-auto relative p-8 md:p-20 mx-6">
                        Désolé, mais nous ne traitons pas pour le moment les DPE immeubles ou des DPE issues de DPE immeuble.
                        Si vous souhaitez une recommandation sur un DPE individuel, veuillez choisir un autre DPE ou alors
                        nous vous proposons de réaliser un DPE propre à votre logement.
                        Vous trouverez la liste des agents que nous avons trouver au plus proche de votre bien sur notre
                        <a href="https://wattless.fr/prestataire-rge/" target="_blank" rel="noreferrer"
                           className=" text-green-600 text-sm font-medium underline ml-1">site
                        </a>
                    </div>

                    <div className="flex justify-between w-full md:px-12">
                        <WButton onClick={handlePrevious} variant="primary"
                                 className="bottom-4  !font-normal !border-[1px] mt-12">
                            <ChevronLeftIcon/>Précédent</WButton>
                        <WButton variant="secondary"
                                 className="bottom-4  mt-12 ">En savoir plus</WButton>
                    </div>

                </div>
            </div>
        </>);
};

export default Dpeunmanagedbuilding;