import React, {useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
import {useSelector, useDispatch} from 'react-redux';
import {selectUser} from "../redux/slices/userSlice";
import {ChevronDownIcon} from '@heroicons/react/16/solid';
import iconFolder from "../assets/icons/folder.svg";
import iconUsers from "../assets/icons/users.svg";
import iconProfil from "../assets/icons/profil.svg";
import {logout} from "../redux/slices/authSlice";
import PermissionGate from "../components/PermissionGate";
{/*import AIChat from "../components/AIChat";*/}

const SingleLayout: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const isActive = (path: string) => location.pathname === path;

    return (
        <div className="min-h-screen bg-[#F5F6FA] flex flex-col">
            {/* Header */}
            <header className="w-full h-[65px] bg-white flex items-center justify-between px-6 shadow">
                {/* Logo à gauche */}
                <div className="flex items-center">
                    <Link to="/">
                        <Logo className="h-8 w-auto"/>
                    </Link>
                </div>

                <div className="flex space-x-6">

                    {/* Lien vers les projets */}
                    <PermissionGate permissions={['PERMISSION.MENU.PROJECTS']}>
                        <div
                            className={`flex align-middle justify-center transition duration-300 ease-in-out ${
                                isActive('/projects')
                                    ? 'text-green-600 font-medium underline cursor-default'
                                    : 'text-[#7C90AA] hover:text-gray-600 hover:underline cursor-pointer'
                            }`}
                            onClick={() => navigate('/projects')}
                        >
                            <img src={iconFolder} className="h-6" alt="project"/>
                            <div className="ml-3 hidden md:flex">Mes projets</div>
                        </div>
                    </PermissionGate>

                    {/* Lien vers les utilisateurs */}
                    <PermissionGate permissions={['PERMISSION.MENU.USERS']}>
                        <div
                            className={`flex align-middle justify-center transition duration-300 ease-in-out ${
                                isActive('/users')
                                    ? 'text-green-600 font-medium underline cursor-default'
                                    : 'text-[#7C90AA] hover:text-gray-600 hover:underline cursor-pointer'
                            }`}
                            onClick={() => navigate('/users')}
                        >
                            <img src={iconUsers} className="h-6" alt="users"/>
                            <div className="ml-3 hidden md:flex">Utilisateurs</div>
                        </div>
                    </PermissionGate>

                    {/* Profil utilisateur */}
                    <div
                        className="relative flex align-middle justify-center cursor-pointer transition duration-300 ease-in-out group"
                        onMouseEnter={() => setIsDropdownOpen(true)} // Ouvre le menu
                    >
                        <span className="flex text-[#7C90AA] justify-center flex-row font-medium ">
                            <img src={iconProfil} className="h-7" alt="profile"/>
                            <div
                                className="ml-3 hidden md:flex group-hover:text-gray-600 group-hover:underline">
                                {user && user.username}</div>
                        </span>

                        {/* Icône de flèche vers le bas */}
                        <ChevronDownIcon
                            className="ml-2 h-5 w-5 cursor-pointer text-[#7C90AA] group-hover:text-gray-600 group-hover:underline"
                        />

                        {/* Menu déroulant */}
                        {isDropdownOpen && (
                            <div
                                onMouseLeave={() => setIsDropdownOpen(false)} // Ferme le menu lorsque le focus est perdu
                                className="text-left absolute right-0 pt-10 w-48 z-[80]">
                                <ul className="py-1 bg-white border rounded-lg shadow-lg">
                                    <PermissionGate permissions={['PERMISSION.USERS.INFORMATION']}>
                                        <li
                                            className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => {
                                                navigate('/profil/info');
                                            }}
                                        >
                                            Mes infos
                                        </li>
                                    </PermissionGate>
                                    <li
                                        className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                        onClick={handleLogout}
                                    >
                                        Déconnexion
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

            </header>

            {/* Contenu principal */}
            <main className="flex flex-col justify-center items-center z-10 ">
                <Outlet/>
                {/*<AIChat />*/}
            </main>
        </div>
    );
};

export default SingleLayout;
