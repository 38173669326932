    import React from "react";
    import { DeperditionEnergetique } from "../../../api/interfaces/ApiInterfaces";
    import maison from "../../../assets/images/Maison.png";

    interface DeperditionProps {
        deperditionEnergetique: DeperditionEnergetique;
        vsDeperditionEnergetique?: DeperditionEnergetique;
    }

    const Deperdition: React.FC<DeperditionProps> = ({ deperditionEnergetique, vsDeperditionEnergetique }) => {

        const getColorClass = () => (vsDeperditionEnergetique ? "text-gray-400" : "text-red-600");

        // Fonction pour arrondir à 2 décimales et gérer null/undefined
        const roundToTwoDecimals = (value: string | null | undefined) => {
            const parsedValue = parseFloat(value ?? "0"); // Si value est null ou undefined, on remplace par "0"
            return isNaN(parsedValue) ? "0" : parsedValue.toFixed(2); // Arrondir à 2 décimales
        };

        return (
            <div className="m-2 bg-white flex flex-col flex-1 w-1/2 rounded-[10px] text-center justify-center min-h-[400px]">
                <div className="p-8 flex flex-col flex-1">
                    <div className="text-left font-bold text-xl mb-3">Déperdition énergétique</div>
                    <div className="relative h-full flex flex-col justify-between">
                        <div className="relative flex flex-row justify-between ">
                            {/* Renouvellement de l’air */}
                            <div className="top-0 left-0">
                                <div className="text-gray-400 font-bold text-[14px]">Renouvellement de l’air :</div>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.renouvellementAir.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.renouvellementAir.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <> vs <span className="text-green-600">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.renouvellementAir.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.renouvellementAir.donneeEnPourcentage)}%
                                        </span></>
                                    )}
                                </div>
                            </div>

                            {/* Planchers hauts */}
                            <div className="top-0 right-0">
                                <span className="text-gray-400 font-bold text-[14px]">Planchers hauts :</span>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.plancherHaut.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.plancherHaut.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <div className="flex flex-col"> vs <span className="text-green-600">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.plancherHaut.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.plancherHaut.donneeEnPourcentage)}%
                                        </span></div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="relative flex flex-row justify-between flex-1">
                            {/* Murs */}
                            <div className="right-0 justify-center text-right flex flex-col">
                                <div className="text-gray-400 font-bold text-[14px]">Murs :</div>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.murs.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.murs.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <div className="flex flex-col"> vs <span className="text-green-600">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.murs.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.murs.donneeEnPourcentage)}%
                                        </span></div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col flex-1 justify-center relative flex-shrink-0 hidden sm:flex">
                                <img src={maison} className="mx-6" alt={'Déperditions énergétique'}/>
                            </div>
                            {/* Menuiseries */}
                            <div className="right-0 justify-center text-left flex flex-col">
                                <div className="text-gray-400 font-bold text-[14px]">Menuiseries :</div>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.menuiserie.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.menuiserie.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <div className="flex flex-col"> vs <span className="text-green-600">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.menuiserie.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.menuiserie.donneeEnPourcentage)}%
                                        </span></div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="relative flex flex-row justify-between ">
                            {/* Ponts thermiques */}
                            <div className="bottom-0 left-0 ">
                                <div className="text-gray-400 font-bold text-[14px]">Ponts thermiques :</div>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.pontThermiques.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.pontThermiques.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <div className="flex flex-col"> vs <span className="text-[20px]">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.pontThermiques.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.pontThermiques.donneeEnPourcentage)}%
                                        </span></div>
                                    )}
                                </div>
                            </div>

                            {/* Planchers bas */}
                            <div className="bottom-0 right-0">
                                <div className="text-gray-400 font-bold text-[14px]">Planchers bas :</div>
                                <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                    {roundToTwoDecimals(deperditionEnergetique.plancherBas.donnee)} W/K ={" "}
                                    {roundToTwoDecimals(deperditionEnergetique.plancherBas.donneeEnPourcentage)}%
                                    {vsDeperditionEnergetique && (
                                        <div className="flex flex-col"> vs <span className="text-green-600">
                                            {roundToTwoDecimals(vsDeperditionEnergetique.plancherBas.donnee)} W/K ={" "}
                                            {roundToTwoDecimals(vsDeperditionEnergetique.plancherBas.donneeEnPourcentage)}%
                                        </span></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    export default Deperdition;
