import React, {useState} from 'react';
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import {useMutation} from "react-query";
import {createProject, updateProject, verifyDPE} from "../../../api/project";
import {useDispatch} from "react-redux";
import {addProject} from "../../../redux/slices/projectSlice";
import {useNavigate} from "react-router-dom";
import iconFind from "../../../assets/icons/find.svg";
import WLoader from "../../../components/WLoader/index.";

const Ademe: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [libelle, setLibelle] = useState<string>();
    const [ademe, setAdeme] = useState<string>("");
    const [showError, setShwoError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {mutate} = useMutation(async ({ademe, libelle}: { ademe: string, libelle: string }) => {
        setIsLoading(true);
        try {
            const project = await createProject(ademe);

            project.libelle = libelle
            await updateProject(project);

            return project;
        } catch (error) {
            console.error('Project creation error:', error);
            throw error; // Permet à `onError` d'être bien déclenché
        } finally {
            setIsLoading(false);
        }
    }, {
        onSuccess: (data) => {
            dispatch(addProject(data));
            setIsLoading(false);
            navigate('/validation');
            console.log('Project created successfully:', data);
        },
        onError: (error) => {
            console.error('Project creation error:', error);
        },
    });

    const handleAdeme = async () => {
        try {
            if (!ademe || !libelle) {
                setShwoError(true);
                return;
            }

            setShwoError(false);
            setIsLoading(true);

            // Vérification du DPE
            const constraintDPE = await verifyDPE(ademe);
            console.log('Résultat vérification DPE:', constraintDPE);

            if (constraintDPE.notFound) {
                setIsLoading(false);
                navigate('/dpenotfound');
                return;
            }

            if (constraintDPE.isVersionInferiorAtTwoPointTwo) {
                setIsLoading(false);
                navigate('/dpeunmanagedversion');
                return;
            }

            if (constraintDPE.isBuilding) {
                setIsLoading(false);
                navigate('/dpeunmanagedbuilding');
                return;
            }

            if (constraintDPE.isEtiquetteA) {
                setIsLoading(false);
                navigate('/dpeetiquettea');
                return;
            }

            // Si toutes les vérifications sont OK, créer le projet
            await mutate({ ademe, libelle });

        } catch (error) {
            console.error('Erreur lors de la vérification ADEME:', error);
            setIsLoading(false);
            // Gérer l'erreur (afficher un message, etc.)
        }
    };

    if (isLoading) {
       return <WLoader message={'Merci de patienter le temps de rechercher votre actif'}/>
    }

    return (
        <>
            <div className="text-left mb-6">

                <div className="flex mt-12 mx-6">
                    <img src={iconFind} alt="Check Icon" className="h-9 text-gray-500"/>
                    <div>
                        <div className="font-bold text-lg  ml-3 mr-3">Identifiez votre actif immobilier</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Renseignez votre numéro ADEME pour que nous puissions récupérer les données de l’actif
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md m-auto mt-12 relative p-8 md:p-20 mx-6">

                    <WInput
                        name="name"
                        placeholder="Nom du projet"
                        required={true}
                        value={libelle}
                        className="mb-8"
                        showError={(showError && (libelle === undefined || libelle === null || libelle === ""))}
                        onChange={(e) => setLibelle(e.target.value)}/>

                    <WInput
                        name="ademe"
                        placeholder="Renseignez le N° ADEME"
                        required={true}
                        value={ademe}
                        showError={showError && (ademe === undefined || ademe === null || ademe === "")}
                        onChange={(e) => setAdeme(e.target.value)}/>

                    <a href="https://wattless.fr/ou-trouver-mon-numero-ademe/" target="_blank"
                       rel="noreferrer"
                       className="block text-green-600 text-sm font-medium underline mt-12">
                        Où trouver mon numéro d’ADEME ?
                    </a>
                    <a href="https://wattless.fr/comment-utilisons-nous-votre-numero-ademe/" target="_blank"
                       rel="noreferrer"
                       className="block text-green-600 text-sm font-medium underline mt-3">
                        Comment utilisons-nous votre numéro d’ADEME ?
                    </a>
                    <a href="https://wattless.fr/je-nai-pas-de-numero-dademe/" target="_blank"
                       rel="noreferrer"
                       className="block text-green-600 text-sm font-medium underline mt-3">
                        Je n’ai pas de numéro d’ADEME
                    </a>

                    <div className="flex flex-row m-auto justify-end ">
                        <WButton variant="secondary" onClick={handleAdeme}
                                 className="bottom-4 right-3 mt-12">Suivant</WButton>
                    </div>
                </div>
            </div>
        </>);
};

export default Ademe;