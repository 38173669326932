import React from 'react';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import dpeA from "../../../assets/icons/DPE/DPE A.svg";

const Dpeetiquettea: React.FC = () => {
    const navigate = useNavigate();

    const handlePrevious = () => {
        navigate('/ademe')
    };

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
    };

    return (
        <>
            <div className="text-left mb-6">

                <div className="flex mt-12 mx-6">

                    <div>
                        <div className="font-bold text-lg  ml-1 mr-3">ERREUR</div>
                        <div className="font-normal m-auto ml-1 mr-3">
                            Votre DPE n&apos;est pas éligible
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md m-auto mt-12 relative p-8 md:p-20 mx-6">

                    <div className="w-1/2 grid place-items-center">
                        <img
                            src={dpeImages["A"]}
                            alt={"A"}
                            className="h-40 m-3"
                        />
                    </div>

                    <div className="rounded-[10px]  m-auto relative p-8 md:p-20 mx-6">
                        <p>WOW, félicitations, votre DPE actuel est <a  className=" text-green-800 text-sm font-medium ml-1">A
                        </a> .</p>
                        <p>Votre actif ne nécessite aucune action d&apos;optimisation de performance énergétique particulière.</p>
                        <p>Evidement, on peut toujours faire mieux et arriver à un actif complètement passif voir à un actif positif, c&apos;est à dire qui produit plus d&apos;énergie qu&apos;il n&apos;en consomme.</p>
                    </div>

                    <div className="flex justify-between w-full md:px-12">
                        <WButton onClick={handlePrevious} variant="primary"
                                 className="bottom-4  !font-normal !border-[1px] mt-12">
                            <ChevronLeftIcon/>Précédent</WButton>
                        <WButton variant="secondary"
                                 className="bottom-4  mt-12 ">En savoir plus</WButton>
                    </div>

                </div>
            </div>
        </>);
};

export default Dpeetiquettea;