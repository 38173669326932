import React, { useState, useRef, useEffect } from 'react';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {codeVerificationEmail} from "../../../api/payment";

const ValidEmail: React.FC = () => {
    const [code, setCode] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Si vous travaillez avec des éléments input.
    const navigate = useNavigate();
    const { code: urlCode } = useParams<{ code: string }>(); // Récupérer le code depuis l'URL

    // Configurer la mutation React Query
    const mutation = useMutation(codeVerificationEmail, {
        onSuccess: (isValid) => {
            if (isValid) {
                setErrorMessage(null);
                navigate('/confirm-email'); // Rediriger si la validation est réussie
            } else {
                setErrorMessage("Le code est invalide. Veuillez réessayer.");
            }
        },
        onError: (error) => {
            setErrorMessage("Une erreur est survenue lors de la vérification. Veuillez réessayer.");
            console.error("Erreur de vérification:", error);
        }
    });

    // Si un code est passé via l'URL, il est automatiquement utilisé
    useEffect(() => {
        if (urlCode && /^\d{6}$/.test(urlCode)) {
            setCode(urlCode);

            // Remplit chaque champ avec le chiffre correspondant
            urlCode.split('').forEach((digit, idx) => {
                if (inputRefs.current && inputRefs.current[idx]) {
                    (inputRefs.current[idx] as HTMLInputElement).value = digit;
                }
            });
        }
    }, [urlCode]);

    // Fonction pour gérer la saisie du code
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        // Assurez-vous que l'utilisateur ne peut entrer qu'un chiffre
        if (/[^0-9]/.test(value)) return;

        // Met à jour le code avec le nouveau chiffre
        const newCode = code.split('');
        newCode[index] = value;
        setCode(newCode.join(''));
        setErrorMessage(null);

        // Déplace automatiquement le focus vers le champ suivant
        if (value && index < 5 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    // Gestion du copier-coller
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData('Text').slice(0, 6); // Prend les 6 premiers caractères
        if (/^\d{6}$/.test(pastedData)) {
            setCode(pastedData);

            // Remplit chaque champ avec le chiffre correspondant
            pastedData.split('').forEach((digit, idx) => {
                if (inputRefs.current && inputRefs.current[idx]) {
                    (inputRefs.current[idx] as HTMLInputElement).value = digit;
                }
            });

            // Déplace le focus au dernier champ
            inputRefs.current[5]?.focus();
        }
        e.preventDefault(); // Empêche le comportement par défaut du collage
    };

    // Fonction de soumission du code
    const handleSubmit = () => {
        if (code.length !== 6) {
            setErrorMessage("Le code doit comporter 6 chiffres.");
            return;
        }

        // Lancer la mutation React Query pour vérifier le code
        mutation.mutate(code);
    };

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <WInfo>Un code de vérification a été envoyé à votre adresse e-mail. Entrez le code ci-dessous.</WInfo>

            <div className="font-bold text-lg mt-3">Vérification de votre e-mail</div>
            <div className="font-normal">Entrez le code à 6 chiffres reçu par e-mail :</div>

            <div className="flex gap-4 mt-6">
                {/* Champs de saisie pour le code à 6 chiffres */}
                {[...Array(6)].map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={code[index] || ''}
                        onChange={(e) => handleChange(e, index)} // Lier handleChange avec l'index
                        onPaste={handlePaste} // Gestion du copier-coller
                        ref={(el) => (inputRefs.current[index] = el)} // Enregistrer la référence
                        className="bg-gray-200 w-12 h-14 text-center rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                    />
                ))}
            </div>

            {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}

            <div className="flex gap-6 mt-16 m-auto flex-col">
                <WButton variant="secondary" onClick={handleSubmit} disabled={mutation.isLoading}>
                    {mutation.isLoading ? "Vérification en cours..." : "Vérifier le code"}
                </WButton>
            </div>
        </div>
    );
};

export default ValidEmail;
