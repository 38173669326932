import React from 'react';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";

const Erreurgenerationscenario: React.FC = () => {
    const navigate = useNavigate();

    const handlePrevious = () => {
        navigate('/ademe')
    };

    return (
        <>
            <div className="text-left mb-6">

                <div className="flex mt-12 mx-6">

                    <div>
                        <div className="font-bold text-lg  ml-1 mr-3">ERREUR</div>
                        <div className="font-normal m-auto ml-1 mr-3">

                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md m-auto mt-12  relative p-8 md:p-20 mx-6">

                    <div className="bg-red-100 rounded-[10px] border-2 border-dashed border-red-500 shadow-md m-auto relative p-8 md:p-20 mx-6">
                        Désolé mais nous avons rencontré un problème dans notre processus de génération des scénarios de rénovation énergétique de
                        votre appartement. Nos équipes vont tout mettre en œuvre pour débloquer cette situation et vous reviendront dans les plus bref délais.
                        Nous vous prions de nous excuser pour ce désagrément.
                    </div>

                    <div className="flex justify-between w-full md:px-12">
                        <WButton onClick={handlePrevious} variant="primary"
                                 className="bottom-4  !font-normal !border-[1px] mt-12">
                            <ChevronLeftIcon/>Précédent</WButton>
                        <WButton variant="secondary"
                                 className="bottom-4  mt-12 ">En savoir plus</WButton>
                    </div>

                </div>
            </div>
        </>);
};

export default Erreurgenerationscenario;