import React from 'react';
import { HouseBg } from '../../assets/icons/components/HouseBg';
import { ApartmentBg } from '../../assets/icons/components/ApartmentBg';
import { UserWattlessBg } from '../../assets/icons/components/UserWattlessBg';
import { InviteBg } from '../../assets/icons/components/InviteBg';
import { AdminBg } from '../../assets/icons/components/AdminBg';
import { AdminAgentBg } from '../../assets/icons/components/AdminAgentBg';
import { AgentBg } from '../../assets/icons/components/AgentBg';

interface WBackgroundImageProps {
    type: string;
    className?: string;
}

const WBackgroundImage: React.FC<WBackgroundImageProps> = ({ type, className = '' }) => {

    const getBgImageComponent = () => {

        console.log('Background type:', type);

        if(type === undefined) return <ApartmentBg />;

        switch(type.toUpperCase()) {
            case "ROLE_ADMIN":
                return <AdminBg />;
            case "ROLE_USER_WATTLESS":
                return <UserWattlessBg />;
            case "ROLE_ADMIN_AGENT":
                return <AdminAgentBg />;
            case "ROLE_AGENT":
                return <AgentBg />;
            case "ROLE_INVITE":
                return <InviteBg />;
            case "MAISON":
                return <HouseBg />;
            default:
                return <ApartmentBg />;
        }
    };
    
    return (
        <div className={`absolute inset-0 w-full h-full overflow-hidden ${className}`}>
            {getBgImageComponent()}
        </div>
    );
};

export default WBackgroundImage;