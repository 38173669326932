import React from "react";
import dpeA from "../../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../../assets/icons/DPE/DPE G.svg";

interface ObjectifInfoProps {
    classeActuelle: string;
}

const dpeImages: { [key: string]: string } = {
    A: dpeA,
    B: dpeB,
    C: dpeC,
    D: dpeD,
    E: dpeE,
    F: dpeF,
    G: dpeG,
};

const ObjectifInfo: React.FC<ObjectifInfoProps> = ({ classeActuelle}) => {

    const textes: { [key: string]: JSX.Element } = {
        G: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2025</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        F: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2028</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        E: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2025</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        D: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        C: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        B: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        A: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>A noter que votre logement se trouve dans la meilleure classe énergétique possible d’après les normes actuelles. Félicitation !</p>
            </>
        ),
    };

    const description = textes[classeActuelle] || "Lettre invalide. Veuillez fournir une lettre entre A et G.";

    return (
        <div className="flex w-full flex-col ">
            <div
                className="flex items-center p-2 space-x-10 text-sm border-dotted border-2 border-red-500 rounded-lg text-center">
                <div><img src={dpeImages[classeActuelle]} alt={classeActuelle} className="h-40 m-3"/>
                </div>
                <div className="flex flex-col text-left">
                    <div className="font-bold text-lg mb-3">Votre DPE actuel : {classeActuelle}</div>
                    {description}
                </div>
            </div>
        </div>
    )
};

export default ObjectifInfo;