import React, {useState} from 'react';
import iconObj from "../../../assets/icons/actions-plan2.svg";
import iconWind from "../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../../assets/icons/icon optim/chauffage.svg";

import {selectProjectById} from "../../../redux/slices/projectSlice";
import {useNavigate, useParams} from "react-router-dom";
import WObjective from "../../../components/WObjective";
import { useQuery} from "react-query";
import {getPlanAction} from "../../../api/project";
import WToast from "../../../components/WToast";
import Menu from "../menu";
import WLoader from "../../../components/WLoader/index.";
import ObjectifInfo from "./ObjectifInfo";
import WButton from "../../../components/Wbutton";
import PermissionGate from "../../../components/PermissionGate";
import iconIsoInt from "../../../assets/icons/icon optim/iso-int.svg";
import iconIsoExt from "../../../assets/icons/icon optim/iso-ext.svg";
import iconIsoPhautComble from "../../../assets/icons/icon optim/iso-combles.svg";
import iconIsoPhaut from "../../../assets/icons/icon optim/iso-planche-haut.svg";
import iconSolarPanel from "../../../assets/icons/icon optim/solar-panel.svg";
import iconClimatisation from "../../../assets/icons/icon optim/clim.png";
import iconIsoPbas from "../../../assets/icons/icon optim/iso-planche-bas.svg";
import {useSelector} from "react-redux";

const PlanAction: React.FC = () => {

    /* Récuperation ID param */
    const {id} = useParams<{ id: string }>();
    const numericId = id ? parseInt(id) : null;
    const currentProject = useSelector(selectProjectById(numericId));

    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);

    const {data: planActionData} = useQuery(['planAction'], async () => {
        if (!numericId) return null;
        return await getPlanAction(numericId);
    }, {enabled: !!numericId});

    const action = planActionData && planActionData.find(plan => plan.id === currentProject?.planActionID);

    const handleDetail = () => {
        navigate(`/project/${numericId}/detail/${action?.id}`);
    }

    if (!currentProject) {
        return <WLoader message="Recherche du projet"/>;
    }

    return (
        <div className="w-full max-w-[1200px]">
            <div className="w-full flex justify-between mt-6">
                <Menu projectId={currentProject.id}/>
            </div>
            <div className="text-left mb-6">
                <div className="flex mt-12 mx-6">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3">
                        <div className="font-bold text-lg  ml-3 mr-3">Plans d&apos;action</div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-9">
                    <div className="flex flex-col p-6 md:p-12">
                        <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.PLANSACTION.VIEW.OBJECTIVE']}>
                            {numericId && <ObjectifInfo classeActuelle={currentProject?.classeEnergetiqueActuel }/>}
                            <p className="text-lg font-bold mt-12">Votre Objectif : {action?.libelle}</p>
                            <div onClick={handleDetail}
                                 className="cursor-pointer shadow-md border rounded-lg w-full mt-3 flex flex-row justify-start items-start">
                                <div className="flex flex-row flex-wrap items-stretch mx-3 md:mx-10 my-3">
                                    <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8 md:mr-6">
                                        <div className="mb-6">
                                            <WObjective level={currentProject.classeEnergetiqueActuel}
                                                        objective={currentProject.classeEnergetiqueObjectif}/>
                                        </div>

                                        {action?.typeScenario==="SCENARIO_1" &&
                                            <div className="text-center mb-14">Monte à
                                                <span className="font-bold "> {currentProject.classeEnergetiqueObjectif} </span>
                                                sans perte de surface ni grosse dépense, <span className="font-bold "> sans PAC </span> !
                                            </div>
                                        }

                                        {action?.typeScenario==="SCENARIO_2" &&
                                            <div className="text-center mb-14">Atteins
                                                <span className="font-bold "> {currentProject.classeEnergetiqueObjectif} </span>
                                                à moindre coût et <span className="font-bold "> reste aux normes sans PAC</span>!
                                            </div>
                                        }

                                        {action?.typeScenario==="SCENARIO_3" &&
                                            <div className="text-center mb-14"> Grimpe
                                                <span className="font-bold "> au max </span>
                                                sans PAC, pour
                                                <span className="font-bold "> un DPE au top</span>!
                                            </div>
                                        }

                                        {action?.typeScenario==="SCENARIO_4" &&
                                            <div className="text-center mb-14"><span className="font-bold ">Maximisez</span>
                                                vos gains énergétiques
                                                <span className="font-bold ">au meilleur coût</span> !
                                            </div>
                                        }

                                        {action?.typeScenario==="SCENARIO_5" &&
                                            <div className="text-center mb-14">  <span className="font-bold ">Budget illimité</span>,
                                                mais  <span className="font-bold ">pas forcément optimal</span>.
                                            </div>
                                        }

                                    </div>
                                    <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8 md:mr-6">
                                        <div className="font-bold text-lg">Travaux à prévoir :</div>

                                        {action?.travaux.map((trav, index) => {
                                            const iconMap: Record<string, string> = {
                                                "Isolation intérieurs murs exposés": iconIsoInt,
                                                "Pose double vitrage": iconWind,
                                                "Ventillation": iconVent,
                                                "Chauffage": iconChauf,
                                                "Isolation extérieure murs exposés": iconIsoExt,
                                                "Pose triple vitrage": iconWind,
                                                "Pose simple vitrage": iconWind,
                                                "Isolation extérieure ph exposés": iconIsoPhautComble,
                                                "Isolation intérieurs ph exposés": iconIsoPhaut,
                                                "Pose solar panel": iconSolarPanel,
                                                "Pose climatisation": iconClimatisation,
                                                "Isolation extérieure pb exposés": iconIsoPbas,
                                                "Isolation intérieurs pb exposés": iconIsoPbas,
                                            };

                                            return (
                                                <React.Fragment key={index}>
                                                    <div
                                                        className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                                    <div className="flex space-x-4">
                                                        <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                             alt="Icon"/>
                                                        <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                    <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8">
                                        <div className="font-bold text-lg">Estimations :</div>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                                            className="font-bold"> {action?.surfacePerdue} m²</span></p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie et GES :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.economieEnergie} kWep/m².an</p>
                                        {/*<p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie2} kWep/m².an</p>*/}
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.economieEnergie3} kgCO2/m².an</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.budget}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.montantAides}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.resteACharge}</p>

                                    </div>
                                    <div className="flex w-full justify-end mb-6 mt-3">
                                        <WButton
                                            variant="secondary"
                                            onClick={handleDetail}
                                            className="px-4 mt-6 py-2"
                                        >
                                            Voir les détails
                                        </WButton>
                                    </div>
                                </div>
                            </div>
                        </PermissionGate>

                        {/*
                        {numericId && planActionData && planActionData.length > 1 &&
                            <div>
                                <PermissionGate
                                    permissions={['PERMISSION.PROJECT.DETAIL.PLANSACTION.CHANGED.OBJECTIVE']}>
                                    <p className="text-lg font-bold mt-12">Fixer un nouvel objectif</p>
                                    <WDPESelector projectId={numericId}
                                                  onSelect={handleSelect}
                                                  excludeObjectives={[currentProject.classeEnergetiqueObjectif]}/>

                                    <div className="flex flex-col mt-12 justify-start ">
                                        <a href="https://wattless.fr/estimation-des-travaux-de-renovation/"
                                           target="_blank"
                                           rel="noreferrer"
                                           className="block text-green-600 font-medium underline mt-3">
                                            Comment estimons-nous les travaux à réaliser ?
                                        </a>
                                    </div>
                                </PermissionGate>
                            </div>
                        }
                        */}
                    </div>
                </div>
            </div>
            {showToast && (
                <WToast
                    message="Nouvelle Objectif enregistré avec succès"
                    type="success"
                    onClose={() => setShowToast(false)} // Masque le toast après fermeture
                />
            )}
        </div>);
};

export default PlanAction;