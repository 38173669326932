import React from "react";
import "./App.css";
import AppRoutes from "./navigation/AppRoutes";

function App(): React.JSX.Element { 
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
