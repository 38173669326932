export const UserWattlessBg = () => (
    <svg width="800" height="600" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Symbole d'énergie */}
        <path d="M350 150L450 300H375L425 450L300 275H375L350 150Z" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Cercles d'influence */}
        <circle cx="400" cy="300" r="120" stroke="#94A3B8" strokeWidth="15" fillOpacity="0.5"/>
        <circle cx="400" cy="300" r="180" stroke="#94A3B8" strokeWidth="10" fillOpacity="0.4"/>
        
        {/* Éléments décoratifs */}
        <rect x="350" y="250" width="100" height="100" fill="#94A3B8" fillOpacity="0.3"/>
    </svg>
);