export const AdminAgentBg = () => (
    <svg width="800" height="600" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Badge d'agent admin */}
        <path d="M300 200H500V400H300V200Z" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Étoile de service */}
        <path d="M400 150L425 250L525 250L450 300L475 400L400 350L325 400L350 300L275 250L375 250L400 150Z" 
              fill="#94A3B8" fillOpacity="0.55"/>
        
        {/* Lignes de connexion */}
        <line x1="200" y1="300" x2="600" y2="300" stroke="#94A3B8" strokeWidth="10" fillOpacity="0.4"/>
    </svg>
);