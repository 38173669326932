import React from 'react';
import WButton from "../../../components/Wbutton";
import iconObj from "../../../assets/icons/actions-plan2.svg";
import iconWind from "../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../../assets/icons/icon optim/chauffage.svg";
import iconIsoExt from "../../../assets/icons/icon optim/iso-ext.svg";
import iconIsoInt from "../../../assets/icons/icon optim/iso-int.svg";
import iconIsoPhaut from "../../../assets/icons/icon optim/iso-planche-haut.svg";
import iconIsoPhautComble from "../../../assets/icons/icon optim/iso-combles.svg";
import iconSolarPanel from "../../../assets/icons/icon optim/solar-panel.svg";
import iconClimatisation from "../../../assets/icons/icon optim/clim.png";


import dpeA from "../../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../../assets/icons/DPE/DPE G.svg";

import {useDispatch, useSelector} from "react-redux";
import {selectCurrentProject, updateProject as updateProjectRedux} from "../../../redux/slices/projectSlice";
import {useNavigate} from "react-router-dom";
import WObjective from "../../../components/WObjective";
import {useMutation, useQuery} from "react-query";
import {getPlanAction, updateProject} from "../../../api/project";
import {PlanActionData, Project} from "../../../api/interfaces/ProjectInterfaces";
import iconIsoPbas from "../../../assets/icons/icon optim/iso-planche-bas.svg";

/*
const classeInferior = (classe: string) => {
    const classes = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const index = classes.indexOf(classe);

    return (index === -1 || index === classes.length - 1) ? 'G' : classes[index - 1];
};
 */

const Objective: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);
    const classeActuelle = currentProject?.classeEnergetiqueActuel || 'G'; // Valeur par défaut

    const {data: planAction} = useQuery(['planAction'], async () => {
        if (!currentProject) return null;

        return   await getPlanAction(currentProject?.id);
    }, {enabled: !!currentProject});

    /*
    const {mutate: selectCustom} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess: () => {
            navigate('/objective');
        }
    });
     */

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    const {mutate: select} = useMutation(async ({project}: {
        project: Project
    }) => {
        project.valid = true; //Validé le projet
        project.statut = "VALIDE";
        return await updateProject(project);
    });

    const handleSelect = (planAction: PlanActionData) => {
        if (currentProject) {
            const newProject = {
                ...currentProject,
                classeEnergetiqueObjectif: planAction.classeEnergetiqueObjectique,
                planActionID: planAction.id
            };

            select({
                project: newProject
            });

            dispatch(updateProjectRedux(newProject));
            navigate('/project/'+ currentProject.id + '/info');
        }
    };

    const textes: { [key: string]: JSX.Element } = {
        G: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2025</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        F: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2028</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        E: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2025</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        D: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        C: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        B: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        A: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>A noter que votre logement se trouve dans la meilleure classe énergétique possible d’après les normes actuelles. Félicitation !</p>
            </>
        ),
    };

    const description = textes[classeActuelle] || "Lettre invalide. Veuillez fournir une lettre entre A et G.";

    // Vérifie si currentProject est null
    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    return (
        <>
            <div className="text-left mb-6">
                <div className="flex mt-12 mx-6">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3">
                        <div className="font-bold text-lg  ml-3 mr-3">Objectif DPE</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Vous pouvez faire évoluer votre DPE actuel. <br/>
                            Choisissez un objectif de DPE. Vous pourrez modifier votre objectif par la suite.
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                    <div className="flex flex-col p-6 md:p-12 ">

                        <div className="flex w-full flex-col ">
                            <div
                                className="flex items-center p-2 space-x-10 text-sm border-dotted border-2 border-red-500 rounded-lg text-center">
                                <div><img src={dpeImages[classeActuelle]} alt={classeActuelle} className="h-40 m-3"/>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div className="font-bold text-lg mb-3">Votre DPE actuel : {classeActuelle}</div>
                                    {description}
                                </div>
                            </div>
                        </div>

                        <p className="text-lg font-bold mt-12">Choisissez un objectif DPE</p>
                        <p>ou fixez en un personnalisé en dessous des propositions</p>

                        <div className="flex flex-row flex-wrap justify-center mt-12">
                            {planAction &&  planAction
                                .filter((item) => item.typePlan !== "CUSTOM")
                                .map((action, index) => {
                                return <div key={index}
                                            className="flex flex-col justify-between shadow-md border rounded-lg max-w-[350px] p-8 mx-3 mb-4">
                                    <div>
                                        <div className="font-bold text-lg text-center mb-6">{action.libelle}</div>

                                        <div className="mb-4">
                                            <WObjective level={classeActuelle}
                                                        objective={action.classeEnergetiqueObjectique}/>
                                        </div>

                                        {action.typeScenario==="SCENARIO_1" &&
                                            <div className="text-center mb-14">Monte à
                                                <span className="font-bold "> {action.classeEnergetiqueObjectique} </span>
                                                sans perte de surface ni grosse dépense, <span className="font-bold "> sans PAC </span> !
                                            </div>
                                        }

                                        {action.typeScenario==="SCENARIO_2" &&
                                            <div className="text-center mb-14">Atteins
                                                <span className="font-bold "> {action.classeEnergetiqueObjectique} </span>
                                                à moindre coût et <span className="font-bold "> reste aux normes sans PAC</span>!
                                            </div>
                                        }

                                        {action.typeScenario==="SCENARIO_3" &&
                                            <div className="text-center mb-14"> Grimpe
                                                <span className="font-bold "> au max </span>
                                                sans PAC, pour
                                                <span className="font-bold "> un DPE au top</span>!
                                            </div>
                                        }

                                        {action.typeScenario==="SCENARIO_4" &&
                                            <div className="text-center mb-14"><span className="font-bold ">Maximisez </span>
                                                vos gains énergétiques
                                                <span className="font-bold "> au meilleur coût</span> !
                                            </div>
                                        }

                                        {action.typeScenario==="SCENARIO_5" &&
                                            <div className="text-center mb-14">  <span className="font-bold ">Budget illimité</span>,
                                                mais  <span className="font-bold ">pas forcément optimal</span>.
                                            </div>
                                        }

                                        <div className="font-bold text-lg">Travaux à prévoir :</div>

                                        {action.travaux.map((trav, index) => {
                                            const iconMap: Record<string, string> = {
                                                "Isolation intérieurs murs exposés": iconIsoInt,
                                                "Pose double vitrage": iconWind,
                                                "Ventillation": iconVent,
                                                "Chauffage": iconChauf,
                                                "Isolation extérieure murs exposés": iconIsoExt,
                                                "Pose triple vitrage": iconWind,
                                                "Pose simple vitrage": iconWind,
                                                "Isolation extérieure ph exposés": iconIsoPhautComble,
                                                "Isolation intérieurs ph exposés": iconIsoPhaut,
                                                "Pose solar panel": iconSolarPanel,
                                                "Pose climatisation": iconClimatisation,
                                                "Isolation extérieure pb exposés": iconIsoPbas,
                                                "Isolation intérieurs pb exposés": iconIsoPbas,
                                            };

                                            return (
                                                <React.Fragment key={index}>
                                                    <div
                                                        className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                                    <div className="flex space-x-4">
                                                        <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                             alt="Icon"/>
                                                        <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <div className="font-bold text-lg mt-28">Estimations :</div>

                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                                            className="font-bold"> {action.surfacePerdue} m²</span></p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie et GES :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie} kWep/m².an</p>
                                        {/*<p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie2} kWep/m².an</p>*/}
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie3} kgCO2/m².an</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.budget}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.montantAides}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.resteACharge}</p>

                                        <div className="w-full m-auto flex mt-12">
                                            <WButton variant="secondary" onClick={() => {
                                                handleSelect(action);
                                            }} className="w-full">Sélectionner</WButton>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                        {/*
                        {planAction && planAction.filter((item) => item.typePlan === "CUSTOM").length > 0 &&
                            <div>
                                <div className="font-bold text-lg text-left mb-3 mt-6 ">Fixer un objectif personnalisé</div>
                                <WDPESelector  projectId={currentProject.id}
                                               excludeObjectives={[...planAction
                                                   .filter((item) => item.typePlan !== "CUSTOM")
                                                   .map((item) => item.classeEnergetiqueObjectique),
                                                   currentProject.classeEnergetiqueActuel]}

                                               onSelect={(objective) => {
                                                   handleSelect(objective);
                                               }} />

                                <div className="flex flex-col mt-12 justify-start ">
                                    <a href="https://wattless.fr/estimation-des-travaux-de-renovation/" target="_blank"
                                       rel="noreferrer"
                                       className="block text-green-600 font-medium underline mt-3">
                                        Comment estimons-nous les travaux à réaliser ?
                                    </a>

                                    <div>
                                        <WButton variant="primary" onClick={handlePrevious} className="mt-8">Précédent</WButton>
                                    </div>
                                </div>
                            </div>
                        }
                        */}
                    </div>
                </div>

            </div>

        </>);
};

export default Objective;