import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import 'leaflet/dist/leaflet.css';
import WButton from "../../../components/Wbutton";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {
    ArrowRightEndOnRectangleIcon,
    EyeIcon,
    RectangleGroupIcon,
    TableCellsIcon,
    TrashIcon
} from "@heroicons/react/16/solid";
import {
    deleteUser,
    getUserInfoById,
    getUsers,
    invite,
    isAdmin,
    isAdminAgent,
    isWattlessAdmin,
    updateUser
} from "../../../api/user";
import {t} from "i18next";
import WSwitch from "../../../components/WSwitch";
import {addProjectShare, deleteProjectShare, getProjectUser, getRoles, listProjects} from "../../../api/project";
import {Project, User} from "../../../api/interfaces/ProjectInterfaces";
import WLoader from "../../../components/WLoader/index.";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import WMultiSelect from "../../../components/WMultiSelect";
import WModalConfirmation from "../../../components/WModalConfirmation";
import PermissionGate from "../../../components/PermissionGate";
import usePermission from "../../../hooks/usePermission";
import {FaRegTrashCan} from "react-icons/fa6";
import WToast from "../../../components/WToast";
import WBackgroundImage from "../../../components/WBackgroundImage";

const UsersList: React.FC = () => {
    const [showView, setShowView] = useState(false);
    const [listProject, setListProject] = useState<Project[]>([]);
    const [isCard, setIsCard] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const canViewProject = usePermission(['PERMISSION.MENU.USERS.VIEW.PROJECTS']);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const queryClient = useQueryClient();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState<"success" | "error" | "info">("success");
    const [selectedEmails, setSelectedEmails] = useState<string[] | null>(null);
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [invitingUserID, setInvitingUserID] = useState<number | null>(null);
    const [errors, setErrors] = useState({
        invitingUserID: "",
        selectedEmails: "",
        selectedRole: "",
        selectedProjects: "",
    });

    const handleShowToast = (type: "success" | "error" | "info", message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
    };

    const inviteMutation = useMutation(
        (payload: { userID: number | null; emails: string[] | null; role: string; projectIDs: number[] }) =>
            invite(payload.userID, payload.emails, payload.role, payload.projectIDs),
        {
            onSuccess: () => {
                // Invalider les requêtes pour rafraîchir les données
                queryClient.invalidateQueries("users");
                setShowInviteModal(false);
            },
        }
    );

    const deleteUserMutation = useMutation(
        (payload: { userId: number }) => deleteUser(payload.userId),
        {
            onSuccess: () => {
                // Invalider le cache pour recharger la liste des utilisateurs
                queryClient.invalidateQueries('users');
            }
        }
    );

    const {data: users, isLoading} = useQuery('users', async () => {
        return await getUsers();
    });

    const {data: roles} = useQuery('getRoles', async () => {
        return await getRoles();
    });

    const {data: projects} = useQuery('projects', async () => {
        return await listProjects();
    });

    useEffect(() => {
        if (users && users.length > 0) {
            setInvitingUserID(users[0].id); // Définit le premier utilisateur comme sélectionné par défaut
        }

        if (roles && roles.length > 0) {
            setSelectedRole(roles[0].name); // Définit le premier rôle comme sélectionné par défaut
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [users, roles, projects]);

    const handleSendInvite = () => {

        const newErrors = {
            invitingUserID: "",
            selectedEmails: "",
            selectedRole: "",
            selectedProjects: "",
        };

        // Validation des champs
        if (!invitingUserID && (selectedEmails === null || (selectedEmails && selectedEmails.length === 0))) {
            newErrors.invitingUserID = "Vous devez sélectionner un utilisateur ou entrer au moins une adresse e-mail.";
        }

        if (!invitingUserID && !selectedRole) {
            newErrors.selectedRole = "Veuillez sélectionner un rôle si aucun utilisateur n'est spécifié.";
        }

        if (selectedProjects.length === 0) {
            newErrors.selectedProjects = "Veuillez sélectionner au moins un projet.";
        }

        // Mettre à jour les erreurs et arrêter si une erreur existe
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error !== "")) {
            return;
        }

        // Appel de la mutation si tout est valide
        {
            selectedRole && selectedProjects && inviteMutation.mutate({
                userID: invitingUserID,
                emails: selectedEmails,
                role: selectedRole,
                projectIDs: selectedProjects,
            })
        }
    };

    const addShareMutation = useMutation(
        ({projectId, userId}: { projectId: number; userId: number }) =>
            addProjectShare(projectId, userId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['users']);
                // Recharger la liste des projets
                if (selectedUser?.id) {
                    getProjectUser(selectedUser.id).then(setListProject);
                }
            },
        }
    );

    const removeShareMutation = useMutation(
        ({projectId, userId}: { projectId: number; userId: number }) =>
            deleteProjectShare(projectId, userId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['users']);
                queryClient.invalidateQueries(['projects']);
                // Recharger la liste des projets
                if (selectedUser?.id) {
                    getProjectUser(selectedUser.id).then(setListProject);
                }                
            },
        }
    );

    const handleGoProject = async (userId: number, username: string, role: string) => {
        const list: Project[] = await getProjectUser(userId);
        setListProject(list);
        setSelectedUser({
            id: userId,
            username,
            roles: [{
                id: 0,
                name: role,
                description: null,
                deleted: false,
                dateCreated: '',
                dateDeleted: null,
                dateUpdated: null
            }],
        } as User);
        setShowView(true);
    };

    const handleDisableAllProjects = async () => {
        if (!selectedUser?.id) return;
        try {
            for (const project of listProject) {
                await removeShareMutation.mutateAsync({projectId: project.id, userId: selectedUser.id});
            }
            // Actualiser la liste après suppression
            const updatedList = await getProjectUser(selectedUser.id);
            setListProject(updatedList);
        } catch (error) {
            console.error("Erreur lors de la désactivation de tous les projets :", error);
        }
    };

    const handleAccessToggle = async (userId: number, state: boolean) => {
        const user = (userId) ? await getUserInfoById(userId) : null;
        if(user){
            user.activate = state;
            DeactivateUserMutation.mutate({user});
            handleShowToast(state ? "success" : "error",  state? "L'accès à l'application a été activé pour l'utilisation: " + user.nom + ' ' + user.prenom : "L'accès à l'application a été désactivé pour l'utilisation: " + user.nom + ' ' + user.prenom  );
        }
    };

    const DeactivateUserMutation = useMutation(
        (payload: { user: User }) => updateUser(payload.user),
        {
            onSuccess: () => {
                // Invalider le cache pour recharger la liste des utilisateurs
                queryClient.invalidateQueries('users');
            }
        }
    );


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

    // Ouvrir le modal avec l'ID de l'utilisateur à supprimer
    const openDeleteConfirmation = (userId: number) => {
        setSelectedUserId(userId);
        setIsModalOpen(true);
    };

    // Fermer le modal
    const closeDeleteConfirmation = () => {
        setIsModalOpen(false);
        setSelectedUserId(null);
    };

    // Confirmation de suppression
    const handleConfirmDelete = async () => {
        if (selectedUserId !== null) {
            deleteUserMutation.mutate({userId: selectedUserId});
        }
        closeDeleteConfirmation();
    };

    // Annulation
    const handleCancelDelete = () => {
        closeDeleteConfirmation();
    };

    const handleShareToggle = async (projectId: number, state: boolean) => {
        if (!selectedUser?.id) return;

        try {
            //const project = listProject.find(p => p.id === projectId);
            if (!state) {
                removeShareMutation.mutate({projectId, userId: selectedUser.id});
                handleShowToast("success",  "L'accès au projet a été désactivé");
            } else {
                addShareMutation.mutate({projectId, userId: selectedUser.id});
                handleShowToast("success",  "L'accès au projet a été activé");
            }
        } catch (error) {
            console.error("Erreur lors de la modification du partage:", error);
        }
    };

    if (isLoading) {
        return <WLoader message="Chargement"/>;
    }

    const handleHideToast = () => setShowToast(false);

    const resetModalData = () => {
        setInvitingUserID(null);
        setSelectedEmails(null);
        setSelectedRole(null);
        setSelectedProjects([]);
    };
    
    // Puis, modifiez l'ouverture du modal
    const openInviteModal = () => {
        resetModalData();
        setShowInviteModal(true);
    };

    return (
        <>
            {users && (isCard || windowSize.width < 1000) ? (
                <div className="w-full">
                    <div className="flex flex-row flex-wrap justify-between text-center my-8 mx-6 space-y-4">
                        <div className="font-medium flex justify-center items-center text-2xl">
                            Tous vos utilisateurs
                        </div>
                        <div className="flex space-x-4 justify-center items-center">
                            {windowSize.width >= 1000 && (
                                <TableCellsIcon
                                    className="cursor-pointer h-8 text-[#7c90aa]"
                                    onClick={() => setIsCard(false)}
                                />
                            )}
                            <PermissionGate permissions={['PERMISSION.MENU.USERS.INVITE']}>
                                <WButton
                                    variant="secondary"
                                    className="bottom-4 right-3 flex items-center gap-2"
                                    onClick={() => {
                                        openInviteModal();
                                    }}
                                >
                                    Inviter un nouvel utilisateur
                                </WButton>
                            </PermissionGate>

                        </div>
                    </div>
                    <ResponsiveMasonry
                        className="space-x-4 mx-6"
                        columnsCountBreakPoints={{1: 1, 700: 2, 1150: 3, 1300: 4}}
                    >
                        <Masonry className="space-x-4">
                            {users.map((user, index) => (
                                <div
                                    key={index}
                                    className="mb-4 bg-white w-full rounded-[10px] shadow-md p-8 flex flex-row relative"
                                >
                                    <WBackgroundImage 
                                        type={user.roles?.[0]?.name} 
                                        className="opacity-70" 
                                    />
                                    <div className="absolute top-6 right-4 flex gap-2 z-10">
                                        {canViewProject && !isWattlessAdmin(user)  && !isAdmin(user) && !isAdminAgent(user) ? <button
                                                className="text-blue-500 hover:underline text-2xl flex items-center space-x-2"
                                                onClick={() =>
                                                    handleGoProject(user.id, user.username, user.roles[0].name)
                                                }
                                            >
                                                <span className="bl-4">{user.nbrProjects} </span>
                                                <EyeIcon className="h-7 w-7"/>
                                            </button> : <div className="text-blue-500  text-2xl flex items-center space-x-2">
                                                <span className="bl-4">{user.nbrProjects} </span>
                                                {/*<EyeIcon className="h-7 w-7"/>*/}
                                            </div>
                                        }

                                        {!isWattlessAdmin(user)  && !isAdmin(user) && !isAdminAgent(user) &&
                                        <>
                                            <PermissionGate permissions={['PERMISSION.MENU.USERS.ACCESS']}>
                                                <WSwitch
                                                    defaultState={user.activate}
                                                    onChange={(state) => {
                                                        handleAccessToggle(user.id, state);
                                                    }}
                                                />
                                            </PermissionGate>
                                            <PermissionGate permissions={['PERMISSION.MENU.USERS.DELETE.ACCESS']}>
                                                <button
                                                    className="text-[#7C90AA] hover:text-red-500 hover:underline text-2xl "
                                                    onClick={() => openDeleteConfirmation(user.id)}
                                                    title="Supprimer"
                                                >
                                                    <FaRegTrashCan/>
                                                </button>
                                            </PermissionGate>
                                        </>
                                        }
                                    </div>
                                    <div className="block flex-col text-left relative w-full">
                                        <div className="font-bold">
                                            {`#${user.id} - ${user.nom ?? ""} ${user.prenom ?? ""}`}
                                        </div>
                                        <div>{user.username}</div>
                                        <div>{user.roles && user.roles.length > 0 ? t(user.roles[0].name) : "Aucun rôle"}</div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            ) : (
                <div className="text-left w-full p-5">
                    <div className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto w-full">
                        {users && (
                            <div className="rounded-[10px]">
                                <div className="flex flex-row justify-between text-center m-6">
                                    <div className="font-medium flex justify-center items-center text-2xl">
                                        Tous vos utilisateurs
                                    </div>
                                    <div className="flex space-x-4 justify-center items-center">
                                        {windowSize.width >= 1000 && (
                                            <RectangleGroupIcon
                                                className="cursor-pointer h-8 text-[#7c90aa]"
                                                onClick={() => setIsCard(true)}
                                            />
                                        )}
                                        <PermissionGate permissions={['PERMISSION.MENU.USERS.INVITE']}>
                                            <WButton
                                                variant="secondary"
                                                className="bottom-4 right-3 flex items-center gap-2"
                                                onClick={() => {
                                                    openInviteModal();
                                                }}
                                            >
                                                Inviter un nouvel utilisateur
                                            </WButton>
                                        </PermissionGate>
                                    </div>
                                </div>
                                <table className="table-auto text-left w-full rounded-[10px]">
                                    <thead>
                                    <tr className="bg-[#899BB4] text-white h-14">
                                        <th className="p-3 font-bold">ID</th>
                                        <th className="p-3 font-bold">Nom & Prénom</th>
                                        <th className="p-3 font-bold">E-mail</th>
                                        <th className="p-3 font-bold">Rôle</th>
                                        <th className="p-3 font-bold">Projects</th>
                                        <th className="p-3 font-bold">Accès</th>
                                        <th className="p-3 font-bold">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user, index) => (
                                        <tr key={user.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                            <td className="p-3 font-bold">{`#${user.id}`}</td>
                                            <td className="p-3 font-bold">{`${user.nom ?? ''} ${user.prenom ?? ''}` || "Nom inconnu"}</td>
                                            <td className="p-3 font-medium text-sm">{user.username}</td>
                                            <td className="p-3 font-medium text-sm">
                                                {user.roles && user.roles.length > 0 ? t(user.roles[0].name) : "Aucun rôle"}
                                            </td>
                                            <td className="p-3">

                                                {canViewProject && !isWattlessAdmin(user)  && !isAdmin(user) && !isAdminAgent(user) ?
                                                    <button
                                                        className="text-blue-500 hover:underline text-2xl flex items-center space-x-2"
                                                        onClick={() =>
                                                            handleGoProject(user.id, user.username, user.roles[0].name)
                                                        }
                                                    >
                                                        <span className="bl-4">{user.nbrProjects} </span>
                                                        <EyeIcon className="h-7 w-7"/>
                                                    </button> : <div className="text-blue-500  text-2xl flex items-center space-x-2">
                                                        <span className="bl-4">{user.nbrProjects} </span>
                                                        {/*<EyeIcon className="h-7 w-7"/>*/}
                                                    </div>}
                                            </td>
                                            <td>
                                                {!isWattlessAdmin(user)  && !isAdmin(user) && !isAdminAgent(user) &&
                                                    <PermissionGate permissions={['PERMISSION.MENU.USERS.ACCESS']}>
                                                        <WSwitch
                                                            defaultState={user.activate}
                                                            onChange={(state) => {
                                                                console.log(`Switch modifié pour l'utilisateur ${user.id} :`, state);
                                                                handleAccessToggle(user.id, state);
                                                            }}
                                                        />
                                                    </PermissionGate>
                                                }
                                            </td>
                                            <td className="p-3">
                                                <div className="h-full flex relative flex-1">
                                                    {!isWattlessAdmin(user)  && !isAdmin(user) && !isAdminAgent(user) &&
                                                        <PermissionGate permissions={['PERMISSION.MENU.USERS.DELETE.ACCESS']}>
                                                            <button
                                                                className="text-[#7C90AA] hover:text-red-500 hover:underline text-2xl "
                                                                onClick={() => openDeleteConfirmation(user.id)}
                                                                title="Supprimer"
                                                            >
                                                                <FaRegTrashCan/>
                                                            </button>
                                                        </PermissionGate>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Modal Projets */}
            {showView && selectedUser && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]"
                    onClick={() => setShowView(false)}
                >
                    <div
                        className="bg-white rounded-lg w-full max-w-[1200px] p-8 shadow-lg pb-20"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="font-bold text-left mb-6">
                            Projets liés à l&apos;utilisateur 
                            <span className="text-green-500">{' ' + selectedUser.username }</span> en tant que
                            <span className="text-green-500">
                                {selectedUser.roles && selectedUser.roles.length > 0 ? ' ' + t(selectedUser.roles[0].name) : "Aucun rôle"}
                            </span>
                        </div>
                        <div className="w-[50%] m-auto text-left mt-16">
                            <div className="border-gray-300 border my-2 flex justify-between leading-[3.5rem] px-2">
                                <div>Désactiver l&apos;accès à tous les projets</div>
                                <div>
                                    <WSwitch onChange={handleDisableAllProjects}/>
                                </div>
                            </div>
                            <div className="font-bold mt-8">Désactiver l&apos;accès uniquement aux projets suivants :
                            </div>
                            {listProject.map((project) => (
                                <div
                                    key={project.id}
                                    className="border-gray-300 border my-2 flex justify-between leading-[3.5rem] px-2"
                                >
                                    <div>{project.libelle}</div>
                                    <div>
                                        <WSwitch
                                            defaultState={project.shared}
                                            onChange={(state) => handleShareToggle(project.id, state)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}


            {/* Modal Invitation */}
            {showInviteModal && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black/50 z-[9999]"
                    onClick={() => {
                        resetModalData();
                        setShowInviteModal(false);
                    }}
                >
                    <div
                        className="bg-white rounded-xl w-full max-w-[800px] p-8 shadow-lg"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Header */}
                        <div className="flex justify-between items-center mb-6 border-b border-gray-200 pb-4">
                            <h2 className="text-2xl font-bold text-left flex items-center gap-3">
                                <ArrowTopRightOnSquareIcon className="text-[#7C90AA] h-7 w-7"/>
                                Inviter un utilisateur
                            </h2>
                            <button
                                className="text-gray-400 hover:text-gray-600 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100"
                                onClick={() => {
                                    resetModalData();
                                    setShowInviteModal(false);
                                }}
                            >
                                <span className="text-xl font-medium">×</span>
                            </button>
                        </div>

                        <div className="w-[75%] m-auto text-left">
                            {/* Section Utilisateur Existant */}
                            <div 
                                className={`mb-6 p-6 rounded-lg border-2 cursor-pointer transition-all duration-200 
                                ${invitingUserID ? 'border-[#7C90AA] bg-gray-50' : 'border-gray-200 hover:border-gray-300'}`}
                                onClick={() => {
                                    setSelectedEmails(null);
                                    setSelectedRole(null);
                                    if (!invitingUserID) {
                                        setInvitingUserID(null); 
                                    }
                                }}
                            >
                                <div className="flex items-center gap-3 mb-4">
                                    <div className={`w-4 h-4 rounded-full border-2 flex items-center justify-center
                                        ${invitingUserID ? 'border-[#7C90AA]' : 'border-gray-300'}`}>
                                        {invitingUserID && <div className="w-2 h-2 rounded-full bg-[#7C90AA]"></div>}
                                    </div>
                                    <h3 className={`text-lg font-semibold ${invitingUserID ? 'text-[#7C90AA]' : 'text-gray-700'}`}>
                                        Partager avec un utilisateur existant
                                    </h3>
                                </div>
                                
                                {errors.invitingUserID && (
                                    <p className="text-red-500 text-sm mb-2">{errors.invitingUserID}</p>
                                )}
                                <select
                                    value={invitingUserID === null ? '' : invitingUserID} 
                                    onChange={(e) => {
                                        const value = e.target.value ? parseInt(e.target.value, 10) : null;  // Modification ici
                                        setInvitingUserID(value);
                                        setSelectedEmails(null);
                                        setSelectedRole(null);
                                    }}
                                    className={`w-full px-4 py-3 border rounded-lg 
                                        ${invitingUserID 
                                            ? 'border-[#7C90AA] focus:ring-2 focus:ring-[#7C90AA]' 
                                            : 'border-gray-200 focus:ring-2 focus:ring-gray-200'}`}
                                    disabled={selectedEmails !== null}
                                >
                                    <option value="">Sélectionner un utilisateur</option>
                                    {users?.map((user, index) => (
                                        <option key={index} value={user.id}>{user.username}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Section Nouvel Utilisateur */}
                            <div 
                                className={`mb-6 p-6 rounded-lg border-2 cursor-pointer transition-all duration-200
                                ${selectedEmails ? 'border-[#7C90AA] bg-gray-50' : 'border-gray-200 hover:border-gray-300'}`}
                                onClick={() => {
                                    setInvitingUserID(null);
                                    if (!selectedEmails) {
                                        setSelectedEmails([]); 
                                    }
                                }}
                            >
                                <div className="flex items-center gap-3 mb-4">
                                    <div className={`w-4 h-4 rounded-full border-2 flex items-center justify-center
                                        ${selectedEmails ? 'border-[#7C90AA]' : 'border-gray-300'}`}>
                                        {selectedEmails && <div className="w-2 h-2 rounded-full bg-[#7C90AA]"></div>}
                                    </div>
                                    <h3 className={`text-lg font-semibold ${selectedEmails ? 'text-[#7C90AA]' : 'text-gray-700'}`}>
                                        Inviter un nouvel utilisateur
                                    </h3>
                                </div>
                                
                                <div className="space-y-4">
                                    {/* Email Input */}
                                    <div>
                                        {errors.selectedEmails && (
                                            <p className="text-red-500 text-sm mb-2">{errors.selectedEmails}</p>
                                        )}
                                        <input
                                            type="email"
                                            onChange={(e) => {
                                                setSelectedEmails(e.target.value ? e.target.value.split(",").map(email => email.trim()) : null);
                                                setInvitingUserID(null);
                                            }}
                                            placeholder="exemple@email.com, exemple2@email.com"
                                            className={`w-full px-4 py-3 border rounded-lg
                                                ${selectedEmails 
                                                    ? 'border-[#7C90AA] focus:ring-2 focus:ring-[#7C90AA]' 
                                                    : 'border-gray-200 focus:ring-2 focus:ring-gray-200'}`}
                                            disabled={invitingUserID !== null}
                                        />
                                        <p className="text-sm text-gray-500 italic mt-2">
                                            Séparez les adresses e-mail par des virgules
                                        </p>
                                    </div>

                                    {/* Rôle Select */}
                                    <div>
                                        {errors.selectedRole && (
                                            <p className="text-red-500 text-sm mb-2">{errors.selectedRole}</p>
                                        )}
                                        <select
                                            onChange={(e) => {
                                                setSelectedRole(e.target.value);
                                                setInvitingUserID(null);
                                            }}
                                            className={`w-full px-4 py-3 border rounded-lg
                                                ${selectedEmails 
                                                    ? 'border-[#7C90AA] focus:ring-2 focus:ring-[#7C90AA]' 
                                                    : 'border-gray-200 focus:ring-2 focus:ring-gray-200'}`}
                                            disabled={invitingUserID !== null}
                                        >
                                            <option value="">Sélectionner un rôle</option>
                                            {roles?.filter(role => {
                                                const excludedRoles = ["ROLE_ADMIN", "ROLE_USER_WATTLESS", "ROLE_ADMIN_AGENT"];
                                                return !excludedRoles.includes(role.name);
                                            }).map((role, i) => (
                                                <option key={i} value={role.id}>{t(role.name)}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Section Projets */}
                            <div className="mt-8">
                                <h3 className="text-lg font-semibold text-[#7C90AA] mb-4">
                                    Sélection des projets
                                </h3>
                                {errors.selectedProjects && (
                                    <p className="text-red-500 text-sm mb-2">{errors.selectedProjects}</p>
                                )}
                                {projects && (
                                    <WMultiSelect
                                        items={projects}
                                        keyExtractor={(item) => item.id}
                                        labelExtractor={(item) => item.libelle}
                                        onSelectionChange={(selected) =>
                                            setSelectedProjects(selected.map((item) => item.id))
                                        }
                                    />
                                )}
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-center mt-8">
                                <WButton 
                                    variant="secondary" 
                                    onClick={handleSendInvite}
                                    className="px-8 py-3 text-base font-medium hover:shadow-md"
                                >
                                    Envoyer l&apos;invitation
                                </WButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de confirmation */}
            {isModalOpen && (
                <WModalConfirmation
                    isOpen={isModalOpen}
                    title={`Êtes-vous certain de vouloir effacer cet utilisateur ?`}
                    message={`Cette action est irréversible et entraînera l'effacement définitif des données de l'utilisateur.`}
                    footer={`Confirmez ou abandonnez votre choix`}
                    titleConfirme={
                        <>
                            <ArrowRightEndOnRectangleIcon className="inline h-5 mr-3"/>
                            Confirmer
                        </>
                    }
                    titleCancel={
                        <>
                            <TrashIcon className="inline h-5 mr-3"/>
                            Annuler
                        </>
                    }
                    confirmButtonClassName="!bg-gray-400"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                    onClose={closeDeleteConfirmation}
                />
            )}

            {showToast && (
                <WToast
                    message={toastMessage}
                    type={toastType}
                    duration={5000}
                    onClose={handleHideToast}
                />
            )}
        </>
    );
};

export default UsersList;
