import React, {useEffect, useState} from 'react';
import WButton from "../../components/Wbutton";
import {useLocation, useNavigate} from "react-router-dom";
import {TrashIcon, ShareIcon} from '@heroicons/react/16/solid';
import WRemoveProject from "../../components/WRemoveProject";
import PermissionGate from "../../components/PermissionGate";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {invite} from "../../api/user";
import {getProjectUsers, getRoles, listProjects} from "../../api/project";

interface MenuProps {
    projectId: number;
}

const Menu: React.FC<MenuProps> = ({projectId}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path: string) => location.pathname.includes(path);
    const [doRemoveProject, setDoRemoveProject] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState<string[] | null>(null);
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [invitingUserID, setInvitingUserID] = useState<number | null>(null);
    const [errors, setErrors] = useState({
        invitingUserID: "",
        selectedEmails: "",
        selectedRole: "",
    });
    const queryClient = useQueryClient();

    const handleSingleAction = () => {
        navigate(`/project/${projectId}/action`);
    };
    const handleSingleFinancial = () => {
        navigate(`/project/${projectId}/financial`);
    };
    const handleSingleInfo = () => {
        navigate(`/project/${projectId}/info`);
    };
    const handleSingleUsers = () => {
        navigate(`/project/${projectId}/users`);
    };

    const handleSuccessRemove = () => {
        setDoRemoveProject(false);
        navigate('/projects');
    }
    const handleRejectRemove = () => setDoRemoveProject(false);
    const handleOpenRemove = () => setDoRemoveProject(true);

    const resetModalData = () => {
        setInvitingUserID(null);
        setSelectedEmails(null);
        setSelectedRole(null);
    };

    const openInviteModal = () => {
        resetModalData();
        setShowInviteModal(true);
    };

    const handleSendInvite = () => {

        const newErrors = {
            invitingUserID: "",
            selectedEmails: "",
            selectedRole: "ROLE_INVITE",
        };

        // Validation des champs
        if (!invitingUserID && (selectedEmails === null || (selectedEmails && selectedEmails.length === 0))) {
            newErrors.invitingUserID = "Vous devez sélectionner un utilisateur ou entrer au moins une adresse e-mail.";
        }

        if (!invitingUserID && !selectedRole) {
            newErrors.selectedRole = "Veuillez sélectionner un rôle si aucun utilisateur n'est spécifié.";
        }

        // Mettre à jour les erreurs et arrêter si une erreur existe
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error !== "")) {
            return;
        }

        // Appel de la mutation si tout est valide
        {
            selectedRole && projectId && inviteMutation.mutate({
                userID: invitingUserID,
                emails: selectedEmails,
                role: selectedRole,
                projectIDs: [projectId],
            })
        }
    };

    const inviteMutation = useMutation(
        (payload: { userID: number | null; emails: string[] | null; role: string; projectIDs: number[] }) =>
            invite(payload.userID, payload.emails, payload.role, payload.projectIDs),
        {
            onSuccess: () => {
                // Invalider les requêtes pour rafraîchir les données
                queryClient.invalidateQueries("usersProject");
                setShowInviteModal(false);
            },
        }
    );

    const {data: users} = useQuery(['usersProject', projectId], async () => {
        return projectId ? await getProjectUsers(projectId) : [];
    });

    const {data: roles} = useQuery('getRoles', async () => {
        const allRoles = await getRoles();
        return allRoles.filter((r) => r.name === "ROLE_INVITE");
    });

    const {data: projects} = useQuery('projects', async () => {
        return await listProjects();
    });

    useEffect(() => {
        if (users && users.length > 0) {
            setInvitingUserID(users[0].id); // Définit le premier utilisateur comme sélectionné par défaut
        }

        if (roles && roles.length > 0) {
            setSelectedRole(roles[0].name); // Définit le premier rôle comme sélectionné par défaut
        }

    }, [users, roles, projects]);

    return (
        <nav className="w-full">
            <div className="w-full flex justify-between mt-6">
                <div className="text-left items-stretch">
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INFORMATION']}>
                        <WButton variant={isActive('/info') ? 'secondary' : 'primary'}
                                 onClick={handleSingleInfo}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Informations
                        </WButton>
                    </PermissionGate>

                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAILPL.ANSACTION']}>
                        <WButton variant={isActive('/action') ? 'secondary' : 'primary'}
                                 onClick={handleSingleAction}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Plans d&apos;action
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.ETAIL.AIDESFINANCIERES']}>
                        <WButton variant={isActive('/financial') ? 'secondary' : 'primary'}
                                 onClick={handleSingleFinancial}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Aides financières
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.UTILISATEURS']}>
                        <WButton variant={isActive('/users') ? 'secondary' : 'primary'}
                                 onClick={handleSingleUsers}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Utilisateurs
                        </WButton>
                    </PermissionGate>
                </div>
                <div>
                    <PermissionGate permissions={['PERMISSION.PROJECT_DETAIL.DELETE.PROJECT']}>
                        <WButton variant="primary" className="mr-2 mt-2 min-w-[180px]" onClick={handleOpenRemove}>
                            <TrashIcon className="mr-3"/> Effacer
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INVITE']}>
                        <WButton variant="primary"
                                 className="mr-2 mt-2 min-w-[180px]"
                                 onClick={() => {
                                     openInviteModal();
                                 }}><ShareIcon
                            className="mr-3"/> Partager</WButton>
                    </PermissionGate>
                </div>
            </div>
            {doRemoveProject &&
                <WRemoveProject projectId={projectId} onCancel={handleRejectRemove} onSuccess={handleSuccessRemove}/>}

            {showInviteModal && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black/50 z-[9999]"
                    onClick={() => {
                        resetModalData();
                        setShowInviteModal(false);
                    }}
                >
                    <div
                        className="bg-white rounded-xl w-full max-w-[800px] p-8 shadow-lg"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Header */}
                        <div className="flex justify-between items-center mb-6 border-b border-gray-200 pb-4">
                            <h2 className="text-2xl font-bold text-left flex items-center gap-3">
                                <ShareIcon className="text-[#7C90AA] h-7 w-7"/>
                                Partager à un utilisateur
                            </h2>
                            <button
                                className="text-gray-400 hover:text-gray-600 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100"
                                onClick={() => {
                                    resetModalData();
                                    setShowInviteModal(false);
                                }}
                            >
                                <span className="text-xl font-medium">×</span>
                            </button>
                        </div>

                        <div className="w-[75%] m-auto text-left">
                            {/* Section Utilisateur Existant */}
                            <div
                                className={`mb-6 p-6 rounded-lg border-2 cursor-pointer transition-all duration-200 
                                ${invitingUserID ? 'border-[#7C90AA] bg-gray-50' : 'border-gray-200 hover:border-gray-300'}`}
                                onClick={() => {
                                    setSelectedEmails(null);
                                    setSelectedRole(null);
                                    if (!invitingUserID) {
                                        setInvitingUserID(null);
                                    }
                                }}
                            >
                                <div className="flex items-center gap-3 mb-4">
                                    <div className={`w-4 h-4 rounded-full border-2 flex items-center justify-center
                                        ${invitingUserID ? 'border-[#7C90AA]' : 'border-gray-300'}`}>
                                        {invitingUserID && <div className="w-2 h-2 rounded-full bg-[#7C90AA]"></div>}
                                    </div>
                                    <h3 className={`text-lg font-semibold ${invitingUserID ? 'text-[#7C90AA]' : 'text-gray-700'}`}>
                                        Partager avec un utilisateur existant
                                    </h3>
                                </div>

                                {errors.invitingUserID && (
                                    <p className="text-red-500 text-sm mb-2">{errors.invitingUserID}</p>
                                )}
                                <select
                                    value={invitingUserID === null ? '' : invitingUserID}
                                    onChange={(e) => {
                                        const value = e.target.value ? parseInt(e.target.value, 10) : null;  // Modification ici
                                        setInvitingUserID(value);
                                        setSelectedEmails(null);
                                        setSelectedRole(null);
                                    }}
                                    className={`w-full px-4 py-3 border rounded-lg 
                                        ${invitingUserID
                                        ? 'border-[#7C90AA] focus:ring-2 focus:ring-[#7C90AA]'
                                        : 'border-gray-200 focus:ring-2 focus:ring-gray-200'}`}
                                    disabled={selectedEmails !== null}
                                >
                                    <option value="">Sélectionner un utilisateur</option>
                                    {users?.map((user, index) => (
                                        <option key={index} value={user.id}>{user.username}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Section Nouvel Utilisateur */}
                            <div
                                className={`mb-6 p-6 rounded-lg border-2 cursor-pointer transition-all duration-200
                                ${selectedEmails ? 'border-[#7C90AA] bg-gray-50' : 'border-gray-200 hover:border-gray-300'}`}
                                onClick={() => {
                                    setInvitingUserID(null);
                                    if (!selectedEmails) {
                                        setSelectedEmails([]);
                                    }
                                }}
                            >
                                <div className="flex items-center gap-3 mb-4">
                                    <div className={`w-4 h-4 rounded-full border-2 flex items-center justify-center
                                        ${selectedEmails ? 'border-[#7C90AA]' : 'border-gray-300'}`}>
                                        {selectedEmails && <div className="w-2 h-2 rounded-full bg-[#7C90AA]"></div>}
                                    </div>
                                    <h3 className={`text-lg font-semibold ${selectedEmails ? 'text-[#7C90AA]' : 'text-gray-700'}`}>
                                        Inviter un nouvel utilisateur
                                    </h3>
                                </div>

                                <div className="space-y-4">
                                    {/* Email Input */}
                                    <div>
                                        {errors.selectedEmails && (
                                            <p className="text-red-500 text-sm mb-2">{errors.selectedEmails}</p>
                                        )}
                                        <input
                                            type="email"
                                            onChange={(e) => {
                                                setSelectedEmails(e.target.value ? e.target.value.split(",").map(email => email.trim()) : null);
                                                setInvitingUserID(null);
                                            }}
                                            placeholder="exemple@email.com, exemple2@email.com"
                                            className={`w-full px-4 py-3 border rounded-lg
                                                ${selectedEmails
                                                ? 'border-[#7C90AA] focus:ring-2 focus:ring-[#7C90AA]'
                                                : 'border-gray-200 focus:ring-2 focus:ring-gray-200'}`}
                                            disabled={invitingUserID !== null}
                                        />
                                        <p className="text-sm text-gray-500 italic mt-2">
                                            Séparez les adresses e-mail par des virgules
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center mt-8">
                            <WButton
                                variant="secondary"
                                onClick={handleSendInvite}
                                className="px-8 py-3 text-base font-medium hover:shadow-md"
                            >
                                Envoyer l&apos;invitation
                            </WButton>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Menu;