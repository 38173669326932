import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";
import {authUtils} from "../../../api/auth";
import {useMutation} from "react-query";
import {jwtDecode} from "jwt-decode";
import {getUserById} from "../../../api/user";
import {useDispatch} from "react-redux";
import {loginSuccess} from "../../../redux/slices/authSlice";
import {addUser} from "../../../redux/slices/userSlice";
import {login} from "../../../api/auth";

interface LoginParams {
    username: string;
    password: string;
}
const ConfirmValidEmail: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {mutate} = useMutation(async ({username, password}: LoginParams) => {
        return await login(username, password);
    }, {
        onSuccess: async (data) => {
            dispatch(loginSuccess(data));
            try {
                const decodedToken = jwtDecode(data.accessToken);
                const userId = Number(decodedToken.sub);
                const user = await getUserById(userId);
                dispatch(addUser(user));
            } catch (error) {
                console.error("Erreur lors du décodage du token JWT ou lors de la récupération de l'utilisateur:", error);
            }

            navigate('/projects');
        },
        onError: (error) => {
            console.error('Login error:', error);
        },
    });

    const handleLoginRedirect = async () => {
        try {
            const credentials = authUtils.getStoredCredentials();

            if (credentials) {
                mutate({
                    username: credentials.email,
                    password: credentials.password
                });

                authUtils.clearCredentials();

                navigate('/');
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Erreur lors de la connexion automatique:', error);
            navigate('/login');
        }
    };

    useEffect(() => {
        handleLoginRedirect();
    }, []);

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <WInfo>Votre adresse e-mail est vérifiée. Merci !</WInfo>

            <div className="font-bold text-lg mt-3">C’est parti !</div>
            <div className="font-normal">Connectez-vous et créer votre premier projet</div>

            <div className="flex gap-6 mt-16 m-auto flex-col">
                <WButton variant="secondary" onClick={handleLoginRedirect}>
                    Connectez-vous
                </WButton>
            </div>
        </div>
    );
};

export default ConfirmValidEmail;
